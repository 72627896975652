import { makeObservable, observable } from "mobx";
import { CourseStatsDto } from "../../../infrastructure/types/courseassignments/dto/CourseStatsDto";
import { CourseStatusDto } from "../../../infrastructure/types/courseassignments/dto/CourseStatusDto";

export class CourseAssignmentStats {
  @observable courseId: string;

  @observable completedPassedAssignmentsCount: number;

  @observable totalAssignmentsCount: number;

  @observable activeDaysLeft: number;

  @observable status: CourseStatusDto;

  constructor(dto: CourseStatsDto) {
    makeObservable(this);
    this.courseId = dto.courseId;
    this.completedPassedAssignmentsCount = dto.completedPassedCount;
    this.totalAssignmentsCount = dto.totalCount;
    this.activeDaysLeft = dto.activeDaysLeft;
    this.status = dto.status;
  }
}
