import classNames from "classnames";
import FocusTrap from "focus-trap-react";
import { observer } from "mobx-react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import { Button } from "../button/Button";
import { usePopup } from "../popup/usePopup";
import { ScrollLock } from "../scrollLock/ScrollLock";
import { SvgIcon } from "../svgIcon/SvgIcon";
import { useEscape } from "./useEscape";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  isButtonDisabled?: boolean;
  className?: string;
}

export const Modal: React.FunctionComponent<ModalProps> = observer(
  ({ isOpen, onClose, children, className, isButtonDisabled }) => {
    const { t } = useTranslation();
    const { isDisplayed, backgroundStyles } = usePopup(isOpen);
    useEscape(onClose);
    const modalInnerStyles = useSpring({
      opacity: isOpen ? 1 : 0,
    });

    if (!isDisplayed) {
      return null;
    }
    return (
      <FocusTrap active={isDisplayed} focusTrapOptions={{ allowOutsideClick: true }}>
        <div className={classNames("Modal", className)}>
          <ScrollLock isLocked={isDisplayed} />
          <animated.div style={backgroundStyles}>
            <Button onClick={onClose} type="clear" className="Modal__closeBackgroundButton" disabled={isButtonDisabled}>
              <span className="visuallyHidden">{t("close")}</span>
            </Button>
          </animated.div>
          <div className="Modal__outer">
            <div className="Modal__wrapper">
              <animated.div style={modalInnerStyles}>
                <div className="Modal__panel">
                  <div className="Modal__inner">{children}</div>
                </div>
                <div className="Modal__closeButonWrapper">
                  <Button onClick={onClose} type="circle" size="large" className="Modal__closeButton">
                    <SvgIcon iconName="x" />
                    <span className="visuallyHidden">{t("close")}</span>
                  </Button>
                </div>
              </animated.div>
            </div>
          </div>
        </div>
      </FocusTrap>
    );
  }
);
