export interface HttpClientErrorResponse {
  errorCode: number;
  errorText: string;
}

export class HttpError extends Error {
  public code: number;

  constructor(httpClientResponse: HttpClientErrorResponse) {
    super(httpClientResponse.errorText);
    this.code = httpClientResponse.errorCode;
  }
}
