//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AssignmentStageDto { 
	Invited = "Invited", 
	Future = "Future", 
	NotStarted = "NotStarted", 
	Started = "Started", 
	Historical = "Historical", 
	PastDue = "PastDue"
}
