import { action, makeObservable, observable } from "mobx";
import { FooterLinkDto } from "../../../infrastructure/types/config/dto/FooterLinkDto";
import { FooterLinkTypeDto } from "../../../infrastructure/types/config/dto/FooterLinkTypeDto";
import { assertUnreachable } from "../../../utils/utils";
import { ExternalFooterLink, FooterLinkViewModel, InternalFooterLink } from "./footerLink/FooterLinkViewModel";

export class FooterConfig {
  @observable footerLinks: FooterLinkViewModel[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  setFooterLinks(footerLinks: FooterLinkDto[]) {
    this.footerLinks = footerLinks.map(link => this.createFooterLink(link));
  }

  private createFooterLink(link: FooterLinkDto) {
    switch (link.type) {
      case FooterLinkTypeDto.ExternalLink: {
        return new ExternalFooterLink(link);
      }
      case FooterLinkTypeDto.InternalLink: {
        return new InternalFooterLink(link);
      }
      default:
        return assertUnreachable(link.type);
    }
  }
}
