import { AppUser } from "../application/models/appUser/AppUser";
import { getLoginPath } from "../infrastructure/components/link/LoginLink";
import { RouteConfiguration } from "../infrastructure/types/routes/RouteConfiguration";

export const getUserInitials = (email: string, firstName?: string, lastName?: string) => {
  if (firstName && lastName) {
    const firstLetter = firstName.charAt(0)?.toUpperCase();
    const secondLetter = lastName.charAt(0)?.toUpperCase();
    return `${firstLetter}${secondLetter}`;
  }
  return email.substr(0, 2).toUpperCase();
};

export const getUserDisplayName = (email: string, firstName?: string, lastName?: string) =>
  firstName && lastName ? `${firstName} ${lastName}` : email;

export const getAuthHref = (appUser: AppUser) => {
  if (appUser._type === "loggedIn") {
    return RouteConfiguration.Logout;
  }

  return getLoginPath({ to: "/dashboard", params: {} });
};
