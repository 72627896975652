//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { PendingInvitationDto } from '../../invitation/dto/PendingInvitationDto';
import { IApiRequest } from '../../IApiRequest';

export class GetUserPendingInvitationsRequest extends IApiRequest<PendingInvitationDto[]>
{
	public $type = 'GetUserPendingInvitationsRequest';
	constructor() { super(); }
}
