import React from "react";
import { AppStore } from "../../../domain/app/AppStore";
import { AnonymousAppUser, AppUser } from "./AppUser";

interface UserContext {
  user: AppUser;
}

const userContext = React.createContext<UserContext>({
  user: new AnonymousAppUser(),
});

export const useAppUser = () => React.useContext<UserContext>(userContext).user;

export const useLoggedInAppUser = () => {
  const appUser = React.useContext<UserContext>(userContext).user;

  if (appUser._type !== "loggedIn") {
    throw new Error("Expected logged in user");
  }

  return appUser;
};

export const UserProvider = ({ children, appStore }: { children: React.ReactNode; appStore: AppStore }) => {
  const { Provider } = userContext;

  const Lazy = React.lazy(async () => {
    const user = await appStore.loadAppUser();

    return {
      default: () => <Provider value={{ user }}>{children}</Provider>,
    };
  });

  return <Lazy />;
};
