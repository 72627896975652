import { observable, makeObservable } from "mobx";
import { TestContentId } from "../../../infrastructure/types/content/model/TestContentId";
import { CourseTestDto } from "../../../infrastructure/types/coursecontents/dto/CourseTestDto";
import { ImageDto } from "../../../infrastructure/types/shared/dto/ImageDto";
import { ContentCourse } from "./Course";

export class CourseTest {
  @observable testId: TestContentId;

  @observable introTitle: string;

  @observable introDescription: string;

  @observable introImage: ImageDto;

  @observable thumbnailImage: ImageDto;

  @observable thumbnailTitle: string;

  readonly _type = "courseTest";

  constructor(public course: ContentCourse, dto: CourseTestDto) {
    makeObservable(this);
    this.testId = dto.testId;
    this.introTitle = dto.introTitle;
    this.introDescription = dto.introDescription;
    this.introImage = dto.introImage;
    this.thumbnailImage = dto.thumbnailImage;
    this.thumbnailTitle = dto.thumbnailTitle;
  }
}
