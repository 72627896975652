import classNames from "classnames";
import * as React from "react";
import {
  ArrowDown,
  ArrowDownRight,
  ArrowLeft,
  ArrowRight,
  Check,
  ChevronDown,
  ChevronRight,
  Circle,
  Clock,
  Edit3,
  HelpCircle,
  Lock,
  MoreHorizontal,
  Plus,
  Search,
  Star,
  Tool,
  Trash,
  UserPlus,
  X,
  Filter,
} from "react-feather";

import { ReactComponent as CheckedCircle } from "../../static/images/icons/checked-circle.svg";
import { ReactComponent as Clouds } from "../../static/images/icons/clouds.svg";
import { ReactComponent as Exclamation } from "../../static/images/icons/exclamation.svg";
import { ReactComponent as GyldendalLogo } from "../../static/images/icons/gyldendal-logo-desktop.svg";
import { ReactComponent as Hamburger } from "../../static/images/icons/hamburger.svg";
import { ReactComponent as QudosLogo } from "../../static/images/icons/qudos-logo.svg";
import { ReactComponent as StarFilled } from "../../static/images/icons/star-filled.svg";
import { ReactComponent as Trophy } from "../../static/images/icons/trophy.svg";

export const icons = {
  qudosLogo: QudosLogo,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowDown: ArrowDown,
  circle: Circle,
  chevronDown: ChevronDown,
  chevronRight: ChevronRight,
  plus: Plus,
  userPlus: UserPlus,
  starFilled: StarFilled,
  clouds: Clouds,
  exclamation: Exclamation,
  search: Search,
  x: X,
  trophy: Trophy,
  moreHorizontal: MoreHorizontal,
  clock: Clock,
  star: Star,
  tool: Tool,
  checkedCircle: CheckedCircle,
  gyldendalLogo: GyldendalLogo,
  lock: Lock,
  arrowDownRight: ArrowDownRight,
  hamburger: Hamburger,
  trash: Trash,
  pencil: Edit3,
  check: Check,
  helpCircle: HelpCircle,
  filter: Filter,
};

type SvgIconSize = "small" | "medium" | "large" | "xLarge" | "xxLarge" | "xxxLarge" | "custom";

export type SvgIconName = keyof typeof icons;

interface SvgIconProps {
  iconName: SvgIconName;
  className?: string;
  size?: SvgIconSize;
}

export const SvgIcon: React.FC<SvgIconProps> = ({ iconName, className, size = "large" }) => {
  const Icon = icons[iconName];

  const sizeToPixel: { [key in SvgIconSize]: string | undefined } = {
    small: "8",
    medium: "16",
    large: "24",
    xLarge: "32",
    xxLarge: "48",
    xxxLarge: "80",
    custom: undefined,
  };

  const pixelSize = sizeToPixel[size];

  return <Icon size={pixelSize} width={pixelSize} height={pixelSize} className={classNames("SvgIcon", className)} />;
};
