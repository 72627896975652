import intersection from "lodash/intersection";
import { action, makeObservable, observable } from "mobx";
import { computedFn } from "mobx-utils";
import { ModuleContentId } from "../../../infrastructure/types/content/model/ModuleContentId";
import { TestContentId } from "../../../infrastructure/types/content/model/TestContentId";
import { ModuleProgressDto } from "../../../infrastructure/types/progress/dto/ModuleProgressDto";
import { calculateProgress } from "../../../utils/utils";
import { getCourseElementsCount } from "../../courseContent/utils/getCourseElementsCount";
import { ProgressStore } from "../stores/ProgressStore";
import { ModuleCompletion } from "./ModuleCompletion";

interface Module {
  id: ModuleContentId;
  testId?: TestContentId;
}

interface Course {
  modules: Module[];
  courseTest?: { testId: TestContentId };
}

export class CourseProgress {
  @observable courseAssignmentId: string;

  @observable private modulesProgress: ModuleCompletion[] = [];

  readonly _type = "loaded";

  progress = computedFn((course: Course) => {
    const completedModuleIds = this.completedModuleIds(course.modules);

    const courseElementsCount = getCourseElementsCount(course.modules, course.courseTest);
    return calculateProgress(completedModuleIds.length, courseElementsCount);
  });

  areAllModulesWithTestCompleted = computedFn((modules: Module[]) => {
    const completedModuleIds = this.completedModuleIds(modules);

    return modules.filter(m => m.testId).every(m => completedModuleIds.find(id => id === m.id.value));
  });

  completedModuleIds = computedFn((modules: Module[]) => {
    // We take intersection in case course modules has been deleted in Sanity after user completed it
    return intersection(
      modules.map(module => module.id.value),
      this.modulesProgress.map(moduleProgress => moduleProgress.moduleContentId.value)
    );
  });

  constructor(courseAssignmentId: string, private progressStore: ProgressStore) {
    makeObservable(this);
    this.courseAssignmentId = courseAssignmentId;
  }

  @action.bound
  async completeModule(moduleContentId: ModuleContentId) {
    if (this.isModuleCompleted(moduleContentId) === false) {
      await this.progressStore.completeModule(moduleContentId, this.courseAssignmentId);
      this.markModuleAsCompleted(moduleContentId);
    }
  }

  @action.bound
  markModuleAsCompleted(moduleContentId: ModuleContentId) {
    if (this.modulesProgress.some(mp => mp.moduleContentId.value === moduleContentId.value) === false) {
      this.modulesProgress.push({ moduleContentId, completedAt: new Date(Date.now()) });
    }
  }

  @action.bound
  updateFromDto(modulesProgress: ModuleProgressDto[]) {
    this.modulesProgress = modulesProgress.map(module => new ModuleCompletion(module));
  }

  isModuleCompleted(moduleContentId: ModuleContentId) {
    return this.modulesProgress.find(mp => mp.moduleContentId.value === moduleContentId.value) !== undefined;
  }
}
