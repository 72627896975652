import React from "react";
import { useSpring } from "react-spring";

export const usePopup = (isOpen: boolean) => {
  const [isDisplayed, setIsDisplayed] = React.useState(isOpen);

  React.useEffect(() => {
    isOpen && setIsDisplayed(true);

    return () => {
      setIsDisplayed(false);
    };
  }, [isOpen]);

  const backgroundStyles = useSpring({
    opacity: isOpen ? 1 : 0,
    onResolve: () => !isOpen && setIsDisplayed(false),
  });

  return { isDisplayed, backgroundStyles };
};
