//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { TestDto } from '../../test/dto/TestDto';
import { IApiRequest } from '../../IApiRequest';

export class GetTestRequest extends IApiRequest<TestDto>
{
	public $type = 'GetTestRequest';
	constructor(public data: { id: string, lang?: string }) { super(); }
}
