import { action, computed, makeObservable, observable } from "mobx";
import { TibetPublicLinksDto } from "../../../infrastructure/types/config/dto/TibetPublicLinksDto";

export class TibetPublicLinks {
  @observable private tibetPublicLinks: TibetPublicLinksDto = {
    editMyProfileUrl: "",
  };

  @computed get editProfileUrl() {
    return this.tibetPublicLinks.editMyProfileUrl;
  }

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setLinks(tibetPublicLinks: TibetPublicLinksDto) {
    this.tibetPublicLinks = tibetPublicLinks;
  }
}
