//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GroupForLeaderDto } from '../../group/dto/GroupForLeaderDto';
import { IApiRequest } from '../../IApiRequest';

export class GetGroupsLedByUserRequest extends IApiRequest<GroupForLeaderDto[]>
{
	public $type = 'GetGroupsLedByUserRequest';
	constructor(public data: { userId: string, companyId: string }) { super(); }
}
