import { CompanyId } from "domain/company/CompanyId";
import { computed, makeObservable, observable } from "mobx";
import { AssignmentStageDto } from "../../../infrastructure/types/courseassignments/dto/AssignmentStageDto";
import { GroupCourseAssignmentDto } from "../../../infrastructure/types/courseassignments/dto/GroupCourseAssignmentDto";
import { daysDifferenceFromNowToDate } from "../../../utils/utils";
import { ManagerCourseAssignmentStore } from "../stores/managerCourseAssignmentStore/ManagerCourseAssignmentStore";

export class GroupCourseAssignment {
  @observable id: string;

  @observable companyId: CompanyId;

  @observable groupId: string;

  @observable groupName: string;

  @observable assignedGroupMembersCount: number;

  @observable completedModulesCount: number;

  @observable stage: AssignmentStageDto;

  @observable activeTo: Date;

  @observable hasPassed?: boolean;

  @observable isRemovable: boolean;

  @observable completedAt?: Date;

  @computed get remainingDays() {
    if (this.stage === AssignmentStageDto.PastDue || this.stage === AssignmentStageDto.Historical) {
      return;
    }

    return daysDifferenceFromNowToDate(this.activeTo);
  }

  readonly _type = "groupCourseAssignment";

  constructor(private managerCourseAssignmentStore: ManagerCourseAssignmentStore, dto: GroupCourseAssignmentDto) {
    makeObservable(this);

    this.id = dto.id;
    this.companyId = new CompanyId(dto.companyId);
    this.groupId = dto.groupId;
    this.groupName = dto.groupName;
    this.assignedGroupMembersCount = dto.assignedGroupMembersCount;
    this.completedModulesCount = dto.completedModulesCount;
    this.stage = dto.stage;
    this.activeTo = new Date(dto.activeTo);
    this.hasPassed = dto.hasPassed;
    this.isRemovable = dto.isRemovable;
    this.completedAt = dto.completedAt ? new Date(dto.completedAt) : undefined;
  }

  async remove() {
    await this.managerCourseAssignmentStore.removeGroupCourseAssignment(this.id, this.companyId);
  }
}
