import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import ICU from "i18next-icu";
import { initReactI18next } from "react-i18next";

i18n
  .use(ICU)
  .use(
    new HttpApi(null, {
      loadPath: ([first]) => {
        return `/api/GetTranslations?lang=${first}`;
      },
    })
  )
  .use(initReactI18next)
  .init({
    fallbackLng: "nb",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
