import { action, makeObservable, observable } from "mobx";
import { OptionDto } from "../../../infrastructure/types/test/dto/OptionDto";

export type OptionStatusType = "default" | "wrong" | "shouldBeChecked" | "correct";

export class OptionWithStatus {
  @observable id: string;

  @observable status: OptionStatusType = "default";

  constructor(public option: OptionDto) {
    makeObservable(this);
    this.id = option.id;
  }

  @action.bound
  changeStatus = (status: OptionStatusType) => {
    this.status = status;
  };

  @action.bound
  reset = () => {
    this.changeStatus("default");
  };
}
