//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { UserItemDto } from '../../users/dto/UserItemDto';
import { PagedResultDto } from '../../shared/dto/PagedResultDto';
import { IApiRequest } from '../../IApiRequest';

export class SearchUsersRequest extends IApiRequest<PagedResultDto<UserItemDto>>
{
	public $type = 'SearchUsersRequest';
	constructor(public data: { companyId: string, searchPhrase?: string, skip: number, take: number }) { super(); }
}
