import { action, observable } from "mobx";
import { HttpClient } from "../../../infrastructure/api/HttpClient";
import { CompleteModuleRequest } from "../../../infrastructure/types/api/progress/CompleteModuleRequest";
import { GetModuleProgressByCourseAssignmentRequest } from "../../../infrastructure/types/api/progress/GetModuleProgressByCourseAssignmentRequest";
import { ModuleContentId } from "../../../infrastructure/types/content/model/ModuleContentId";
import { ModuleProgressDto } from "../../../infrastructure/types/progress/dto/ModuleProgressDto";
import { CourseAssignment } from "../../courseAssigning/models/CourseAssignment";
import { CourseProgress } from "../models/CourseProgress";

export class ProgressStore {
  @observable coursesProgress: CourseProgress[] = [];

  constructor(private httpClient: HttpClient) {}

  @action.bound
  public getCourseProgress(courseAssignment: CourseAssignment) {
    const courseProgress = this.coursesProgress.find(cp => cp.courseAssignmentId === courseAssignment.id);

    if (courseProgress) {
      return courseProgress;
    }

    return this.addProgress(courseAssignment);
  }

  @action
  private addProgress(courseAssignment: CourseAssignment) {
    const newCourseProgress = new CourseProgress(courseAssignment.id, this);
    this.coursesProgress.push(newCourseProgress);
    return newCourseProgress;
  }

  async completeModule(moduleContentId: ModuleContentId, courseAssignmentId: string) {
    await this.httpClient.send(new CompleteModuleRequest({ moduleContentId, courseAssignmentId }));
  }

  public async loadCourseAssignmentsProgress(courseAssignmentId: string) {
    const response = await this.httpClient.send(new GetModuleProgressByCourseAssignmentRequest({ courseAssignmentId }));
    this.updateFromDto(response);
  }

  public isModuleCompleted(courseAssignmentId: string, moduleContendId: ModuleContentId) {
    const course = this.coursesProgress.find(c => c.courseAssignmentId === courseAssignmentId);

    if (course !== undefined) {
      return course.isModuleCompleted(moduleContendId);
    }

    return false;
  }

  private updateFromDto(moduleProgressDtos: ModuleProgressDto[]) {
    const assignmentsIds = new Set(moduleProgressDtos.map(mp => mp.courseAssignmentId));
    const progress: CourseProgress[] = [];
    assignmentsIds.forEach(assignmentId => {
      const assignmentProgress = moduleProgressDtos.filter(mp => mp.courseAssignmentId === assignmentId);
      const courseProgress = new CourseProgress(assignmentProgress[0].courseAssignmentId, this);
      courseProgress.updateFromDto(assignmentProgress);
      progress.push(courseProgress);
    });

    this.coursesProgress = progress;
  }
}
