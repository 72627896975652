//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CompanyDto } from '../../company/dto/CompanyDto';
import { IApiRequest } from '../../IApiRequest';

export class GetCompanyRequest extends IApiRequest<CompanyDto>
{
	public $type = 'GetCompanyRequest';
	constructor(public data: { companyId: string }) { super(); }
}
