import axios, { CancelTokenSource } from "axios";
import { SearchGroupsRequest } from "infrastructure/types/api/users/SearchGroupsRequest";
import { GroupItemDto } from "infrastructure/types/users/dto/GroupItemDto";
import { UserAndGroupTypeDto } from "infrastructure/types/users/dto/UserAndGroupTypeDto";
import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { PaginationOptions, SearchPhrase } from "../../../../ui/loadMoreList/PaginatedList";
import { CompanyId } from "../../../company/CompanyId";

export class GroupItem {
  public id: string;

  public name?: string;

  public readonly _type = UserAndGroupTypeDto.Group;

  constructor(dto: GroupItemDto) {
    this.id = dto.id;
    this.name = dto.name;
  }
}

export class GroupSearchStore {
  cancelTokenSource?: CancelTokenSource;

  constructor(private httpClient: HttpClient) {}

  public async getItems(companyId: CompanyId, searchPhrase: SearchPhrase, options: PaginationOptions) {
    const result = await this.loadItems(companyId, searchPhrase, options);

    const { results, total } = result;

    const items = results.map(u => new GroupItem(u));

    return { items, total };
  }

  private async loadItems(companyId: CompanyId, searchPhrase: SearchPhrase, options: PaginationOptions) {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("Canceled because of a new request.");
    }

    this.cancelTokenSource = axios.CancelToken.source();

    const response = await this.httpClient.send(
      new SearchGroupsRequest({
        companyId: companyId.value,
        searchPhrase,
        ...options,
      }),
      this.cancelTokenSource.token
    );

    this.cancelTokenSource = undefined;

    return response;
  }
}
