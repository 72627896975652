//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CoursePackageInfoDto } from '../../coursepackages/dto/CoursePackageInfoDto';
import { IApiRequest } from '../../IApiRequest';

export class GetCoursePackagesRequest extends IApiRequest<CoursePackageInfoDto[]>
{
	public $type = 'GetCoursePackagesRequest';
	constructor(public data: { lang?: string }) { super(); }
}
