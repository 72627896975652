import { CompanyId } from "domain/company/CompanyId";
import { makeObservable, observable } from "mobx";
import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { AddCourseAssignmentsRequest } from "../../../../infrastructure/types/api/courseassignments/AddCourseAssignmentsRequest";
import { GetCourseAssignmentsForGroupMembersRequest } from "../../../../infrastructure/types/api/courseassignments/GetCourseAssignmentsForGroupMembersRequest";
import { GetCourseAssignmentsRequest } from "../../../../infrastructure/types/api/courseassignments/GetCourseAssignmentsRequest";
import { RemoveCourseAssignmentRequest } from "../../../../infrastructure/types/api/courseassignments/RemoveCourseAssignmentRequest";
import { RemoveGroupCourseAssignmentRequest } from "../../../../infrastructure/types/api/courseassignments/RemoveGroupCourseAssignmentRequest";
import { AssignmentSourceDto } from "../../../../infrastructure/types/courseassignments/dto/AssignmentSourceDto";
import { assertUnreachable } from "../../../../utils/utils";
import { GroupCourseAssignment } from "../../models/GroupCourseAssignment";
import { IndividualCourseAssignment } from "../../models/IndividualCourseAssignment";

export type ManagerCourseAssignment = GroupCourseAssignment | IndividualCourseAssignment;

type CourseAssignmentsFilters = ConstructorParameters<typeof GetCourseAssignmentsRequest>[0];

type CourseAssignmentsForGroupMembersFilters = ConstructorParameters<
  typeof GetCourseAssignmentsForGroupMembersRequest
>[0];

export class ManagerCourseAssignmentStore {
  @observable courseAssignments: ManagerCourseAssignment[] = [];

  @observable total = 0;

  constructor(private httpClient: HttpClient) {
    makeObservable(this);
  }

  public async addCourseAssignmentsToUsersAndGroups(
    companyId: CompanyId,
    courseDomainId: string,
    userIds: string[],
    groupIds: string[] | undefined,
    activeFrom: string,
    activeTo: string
  ) {
    const result = await this.httpClient.send(
      new AddCourseAssignmentsRequest({
        companyId: companyId.value,
        courseId: courseDomainId,
        userIds,
        groupIds,
        activeFrom,
        activeTo,
      })
    );
    return result;
  }

  public async load(filters: CourseAssignmentsFilters) {
    const assignmentsWithTotal = await this.loadCourseAssignments(filters);
    this.courseAssignments = assignmentsWithTotal.items;
    this.total = assignmentsWithTotal.total;

    return assignmentsWithTotal;
  }

  public async loadCourseAssignmentsForGroupMembers(filters: CourseAssignmentsForGroupMembersFilters) {
    const assignmentsWithTotal = await this.httpClient.send(new GetCourseAssignmentsForGroupMembersRequest(filters));
    const individualCourseAssignments = assignmentsWithTotal.results.map(
      ca => new IndividualCourseAssignment(this, ca)
    );

    return { items: individualCourseAssignments, total: assignmentsWithTotal.total };
  }

  public async removeIndividualCourseAssignment(id: string, companyId: CompanyId) {
    await this.httpClient.send(new RemoveCourseAssignmentRequest({ id, companyId: companyId.value }));
    this.courseAssignments = this.courseAssignments.filter(i => i.id !== id);
  }

  public async removeGroupCourseAssignment(id: string, companyId: CompanyId) {
    await this.httpClient.send(new RemoveGroupCourseAssignmentRequest({ id, companyId: companyId.value }));
    this.courseAssignments = this.courseAssignments.filter(i => i.id !== id);
  }

  private async loadCourseAssignments(filters: CourseAssignmentsFilters) {
    const getCourseAssignmentsResponse = await this.httpClient.send(new GetCourseAssignmentsRequest({ ...filters }));

    const items = getCourseAssignmentsResponse.results.map(dto => {
      if (dto.source === AssignmentSourceDto.Individual && dto.individualCourseAssignment) {
        return new IndividualCourseAssignment(this, dto.individualCourseAssignment);
      }

      if (dto.source === AssignmentSourceDto.Group && dto.groupCourseAssignment) {
        return new GroupCourseAssignment(this, dto.groupCourseAssignment);
      }

      return assertUnreachable();
    });

    return {
      items,
      total: getCourseAssignmentsResponse.total,
    };
  }
}
