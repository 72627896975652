//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CourseContentInfoDto } from '../../coursecontents/dto/CourseContentInfoDto';
import { IApiRequest } from '../../IApiRequest';

export class GetCoursesContentInfoRequest extends IApiRequest<CourseContentInfoDto[]>
{
	public $type = 'GetCoursesContentInfoRequest';
	constructor(public data: { courseIds: string[], lang?: string }) { super(); }
}
