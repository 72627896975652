//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IApiRequest } from '../../IApiRequest';

export class IsUserMemberOfMyNetworkRequest extends IApiRequest<boolean>
{
	public $type = 'IsUserMemberOfMyNetworkRequest';
	constructor(public data: { userId: string, companyId: string }) { super(); }
}
