import { observable, makeObservable } from "mobx";
import { TestContentId } from "../../../infrastructure/types/content/model/TestContentId";
import { TaskDto } from "../../../infrastructure/types/test/dto/TaskDto";
import { TestDto } from "../../../infrastructure/types/test/dto/TestDto";

export class Test {
  @observable id: TestContentId;

  @observable slug: string;

  @observable title: string;

  @observable description: string;

  @observable threshold: number;

  @observable tasks: TaskDto[];

  readonly _type = "test";

  constructor(dto: TestDto) {
    makeObservable(this);

    this.id = dto.id;
    this.slug = dto.slug;
    this.title = dto.title;
    this.description = dto.description;
    this.threshold = dto.threshold;
    this.tasks = dto.tasks;
  }
}
