import { BadRequestResultTypeDto } from "infrastructure/types/api/error/BadRequestResultTypeDto";
import { HttpClientErrorResponse } from "./HttpError";

export interface HttpCustomErrorClientResponse extends HttpClientErrorResponse {
  type: BadRequestResultTypeDto;
}

export class HttpCustomError extends Error {
  public code: number;

  public type: BadRequestResultTypeDto;

  constructor(httpClientResponse: HttpCustomErrorClientResponse) {
    super(httpClientResponse.errorText);
    this.code = httpClientResponse.errorCode;
    this.type = BadRequestResultTypeDto[httpClientResponse.type];
  }
}
