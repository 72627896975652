//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CourseDto } from '../../courses/dto/CourseDto';
import { IApiRequest } from '../../IApiRequest';

export class GetAssignableCourseDomainsRequest extends IApiRequest<CourseDto[]>
{
	public $type = 'GetAssignableCourseDomainsRequest';
	constructor(public data: { companyId: string }) { super(); }
}
