//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GroupStatsDto } from '../../group/dto/GroupStatsDto';
import { IApiRequest } from '../../IApiRequest';

export class GetSingleGroupStatsRequest extends IApiRequest<GroupStatsDto>
{
	public $type = 'GetSingleGroupStatsRequest';
	constructor(public data: { companyId: string, groupId: string }) { super(); }
}
