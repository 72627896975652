/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/ban-types */
/**
 * Source: https://github.com/0916dhkim/typed-react-router
 */

import { PATHS as Paths } from "./AppPaths";
import { ScrollTargets } from "./AppScrollTargets";

type ExtractRouteParams<T> = string extends T
  ? Record<string, string>
  : T extends `${string}:${infer Param}/${infer Rest}`
  ? { [k in Param | keyof ExtractRouteParams<Rest>]: string }
  : T extends `${string}:${infer Param}`
  ? { [k in Param]: string }
  : {};

export type Path = typeof Paths[number];
export type ScrollTargetName = typeof ScrollTargets[number];

// Object which has matching parameter keys for a path.
export type PathParams<P extends Path> = ExtractRouteParams<P>;

/**
 * Type predicate for checking whether params match the path specs.
 * @example
 * isParams(
 *   '/something/:id',
 *   { id: 'abcd' },
 * ) // returns true.
 *
 * isParams(
 *   '/else/:one',
 *   { two: 'efg' },
 * ) // returns false.
 * @param path target path.
 * @param params params to be checked.
 */
export function isParams<P extends Path>(path: P, params: unknown): params is PathParams<P> {
  if (!(params instanceof Object)) {
    return false;
  }

  const paramSet = new Set(Object.keys(params));

  // Validate params.
  const requiredParams = path
    .split("/")
    .filter(s => s.startsWith(":"))
    .map(s => s.substr(1));

  for (const x of requiredParams) {
    if (!paramSet.has(x)) {
      return false;
    }
  }

  return true;
}

/**
 * Build an url with a path and its parameters.
 * @example
 * buildUrl(
 *   '/a/:first/:last',
 *   { first: 'p', last: 'q' },
 * ) // returns '/a/p/q'
 * @param path target path.
 * @param params parameters.
 */
export const buildUrl = <P extends Path>(path: P, params: PathParams<P>, scrollTarget?: ScrollTargetName): string => {
  let url: string = path;

  // Upcast `params` to be used in string replacement.
  const paramObj: { [i: string]: string } = params;

  for (const key of Object.keys(paramObj)) {
    url = url.replace(`:${key}`, paramObj[key]);
  }

  if (scrollTarget) {
    url += `#${scrollTarget}`;
  }

  return url;
};

export const isPathValid = (locationPathElements: string[]) =>
  Paths.some(p => {
    const pathElements = p.split("/");

    if (locationPathElements.length !== pathElements.length) {
      return false;
    }

    return pathElements.every((pathElement, index) => {
      if (pathElement.startsWith(":")) {
        return true;
      }

      return pathElement === locationPathElements[index];
    });
  });
