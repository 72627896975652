//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export abstract class RouteConfiguration
{
	public static Landing: string = `/`;
	public static Dashboard: string = `/dashboard`;
	public static Api: string = `/api`;
	public static HealthCheck: string = `/api/health`;
	public static Init: string = `/api/init`;
	public static Ping: string = `/api/ping`;
	public static Logout: string = `/logout`;
	public static PostLogout: string = `/post-logout`;
	public static Login: string = `/login`;
	public static Invitation: string = `/invitation`;
	public static AnalyticsKeys: string = `/analytics-keys.js`;
	public static RobotsTxt: string = `/robots.txt`;
	public static Sitemap: string = `/sitemap.xml`;
	public static MailToSupport: string = `support@gyldendal.no`;
	public static GyldendalHomePage: string = `https://www.gyldendal.no`;
	public static GyldendalPrivacyPolicyUrl: string = `https://bokplussnett.gyldendal.no/artikler/gyldendals-bruk-av-informasjonskapsler`;
	public static Cookies: string = `/informasjonskapsler`;
	public static TermsAndConditions: string = `/bruks-og-kjopsvilkar`;
	public static PrivacyPolicy: string = `/personvern`;
}
