import { FooterLinkDto } from "infrastructure/types/config/dto/FooterLinkDto";
import { makeObservable, observable } from "mobx";
import { throwExpression } from "../../../../utils/utils";

export class InternalFooterLink {
  @observable title: string;

  @observable internalUrl: string;

  readonly _type = "internal";

  constructor(dto: FooterLinkDto) {
    makeObservable(this);
    this.title = dto.title;
    this.internalUrl = dto.internalUrl ?? throwExpression("Missing internal url");
  }
}

export class ExternalFooterLink {
  @observable title: string;

  @observable externalUrl: string;

  readonly _type = "external";

  constructor(dto: FooterLinkDto) {
    makeObservable(this);
    this.title = dto.title;
    this.externalUrl = dto.externalUrl ?? throwExpression("Missing external url");
  }
}

export type FooterLinkViewModel = InternalFooterLink | ExternalFooterLink;
