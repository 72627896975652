//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CourseContentInfoDto } from '../../coursecontents/dto/CourseContentInfoDto';
import { IApiRequest } from '../../IApiRequest';

export class GetSelfAssignableCourseContentInfoRequest extends IApiRequest<CourseContentInfoDto[]>
{
	public $type = 'GetSelfAssignableCourseContentInfoRequest';
	constructor(public data: { companyId: string, lang?: string }) { super(); }
}
