//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IApiRequest } from '../../IApiRequest';

export class AddGroupRequest extends IApiRequest<string>
{
	public $type = 'AddGroupRequest';
	constructor(public data: { groupName: string, companyId: string, userIds: string[] }) { super(); }
}
