import { CompanyId } from "domain/company/CompanyId";
import { makeObservable, observable } from "mobx";
import { AssignmentStageDto } from "../../../infrastructure/types/courseassignments/dto/AssignmentStageDto";
import { CourseAssignmentDto } from "../../../infrastructure/types/courseassignments/dto/CourseAssignmentDto";
import { CourseModeDto } from "../../../infrastructure/types/shared/dto/CourseModeDto";
import { CourseAssignmentStore } from "../stores/courseAssignmentStore/CourseAssignmentStore";

export type HistoricalCourseAssignment = CourseAssignment & { completedAt: Date; hasPassed: boolean };

export class CourseAssignment {
  @observable id: string;

  @observable companyId: CompanyId;

  @observable userId: string;

  @observable courseId: string;

  @observable courseTitle: string;

  @observable assignerId?: string;

  @observable activeFrom: Date;

  @observable activeTo: Date;

  @observable completedAt?: Date;

  @observable canBeViewed: boolean;

  @observable hasDiploma: boolean;

  @observable stage: AssignmentStageDto;

  @observable hasPassed?: boolean;

  @observable isRemovable: boolean;

  @observable mode: CourseModeDto;

  @observable isLocked: boolean;

  constructor(private courseAssignmentStore: CourseAssignmentStore, dto: CourseAssignmentDto) {
    makeObservable(this);

    this.id = dto.id;
    this.companyId = new CompanyId(dto.companyId);
    this.userId = dto.userId;
    this.courseId = dto.courseId;
    this.courseTitle = dto.courseTitle;
    this.assignerId = dto.assignerId;
    this.activeFrom = new Date(dto.activeFrom);
    this.activeTo = new Date(dto.activeTo);
    this.completedAt = dto.completedAt ? new Date(dto.completedAt) : undefined;
    this.canBeViewed = dto.canBeViewed;
    this.hasDiploma = dto.hasDiploma;
    this.stage = dto.stage;
    this.hasPassed = dto.hasPassed;
    this.isRemovable = dto.isRemovable;
    this.mode = dto.mode;
    this.isLocked = dto.isLocked;
  }

  async start() {
    await this.courseAssignmentStore.startCourseAssignment(this.id);
  }

  async updateToNewest() {
    const dto = await this.courseAssignmentStore.loadCourseAssignment(this.id);

    this.id = dto.id;
    this.companyId = new CompanyId(dto.companyId);
    this.userId = dto.userId;
    this.courseId = dto.courseId;
    this.courseTitle = dto.courseTitle;
    this.assignerId = dto.assignerId;
    this.activeFrom = new Date(dto.activeFrom);
    this.activeTo = new Date(dto.activeTo);
    this.completedAt = dto.completedAt ? new Date(dto.completedAt) : undefined;
    this.canBeViewed = dto.canBeViewed;
    this.hasDiploma = dto.hasDiploma;
    this.stage = dto.stage;
    this.hasPassed = dto.hasPassed;
    this.isRemovable = dto.isRemovable;
    this.mode = dto.mode;
    this.isLocked = dto.isLocked;
  }
}
