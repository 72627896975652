import DOMPurify from "dompurify";
import { isNil } from "lodash";
import { Path } from "../infrastructure/paths/Paths";
import { ContentId } from "../infrastructure/types/content/model/ContentId";
import { CourseModeDto } from "../infrastructure/types/shared/dto/CourseModeDto";

const mailValidationRegex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const mailSplitRegex = /(?:,| |;)+/;

export const getVimeoId = (vimeoUrl: string) => {
  const videoMatch = vimeoUrl.match(/vimeo.*(?:\/|clip_id=)([0-9a-z]*)/i);

  if (!videoMatch) {
    return null;
  }

  return videoMatch[1];
};

export const throwExpression = (errorMessage: string) => {
  throw new Error(errorMessage);
};

export type IdType = string | number | ContentId;

export const isContentId = (id: IdType): id is ContentId => {
  return (id as ContentId).value !== undefined;
};

export const downloadBlob = async (blob: Blob, fileName: string) => {
  const itemsBlobUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.download = fileName;
  a.href = itemsBlobUrl;
  a.target = "_blank";
  a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
  a.remove();
};

export const daysDifferenceFromNowToDate = (date: Date) => {
  const now = new Date().getTime();
  const dayInMilliseconds = 1000 * 3600 * 24;
  return Math.floor((date.getTime() - now) / dayInMilliseconds);
};

export const getFormattedTime = (date: Date) => {
  const hour = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const formattedHour = hour < 10 ? `0${hour}` : hour;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHour}:${formattedMinutes}`;
};

export const isDefined = <T>(o: T | undefined | null): o is T => {
  return !isNil(o);
};

type Flavoring<FlavorT> = { _type?: FlavorT };
export type Flavor<T, FlavorT> = T & Flavoring<FlavorT>;

type Branding<BrandT> = { _type: BrandT };
export type Brand<T, BrandT> = T & Branding<BrandT>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type Nominal<T, NameT> = T extends object ? Flavor<T, NameT> : Brand<T, NameT>;

export const getCourseUrl = (
  mode: CourseModeDto
): Extract<Path, "/exam/:courseAssignmentId" | "/course/:courseAssignmentId"> => {
  return mode === CourseModeDto.Exam ? "/exam/:courseAssignmentId" : "/course/:courseAssignmentId";
};

export const assertUnreachable = (_?: never) => {
  throw new Error("Didn't expect to get here (exhaustiveness-check)");
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const asProxy = <TAsserted extends TActual, TActual extends object = object>(t: TActual) => {
  const proxy = new Proxy(t, {
    get(obj, prop) {
      if (!(prop in obj)) {
        throw new Error(`Trying to access non-existent property "${String(prop)}" on object ${JSON.stringify(obj)}`);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (obj as any)[prop];
    },
  });
  return proxy as TAsserted;
};

export const tryParseEmails = (userEmails: string) => {
  const emails = userEmails.split(mailSplitRegex);
  let output: string[] | undefined = emails;
  emails.forEach(email => {
    if (mailValidationRegex.test(email) === false) {
      output = undefined;
    }
  });

  return { parsed: output !== undefined, out: output };
};

export const calculateProgress = (completed: number, target: number) => {
  return Math.ceil((completed / target) * 100);
};

export const wait = (time: number) => new Promise(res => setTimeout(res, time));

export const generateMarkups = (number: number, markup: string) => Array(number).fill(markup).join("");

export const waitForElement = (selector: string) => {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const getDisplayText = (text: string) =>
  text.includes(" ") ? `${text.split(" ")[0].charAt(0)}${text.split(" ")[1].charAt(0)}` : `${text.slice(0, 2)}`;

export const sanitizeHtml = (html: string) => {
  return DOMPurify.sanitize(html);
};
