import Cookies from "js-cookie";
import { action, makeObservable, observable } from "mobx";
import { IGtmService } from "../../../infrastructure/gtm/GtmService";
import { CookiesConsentDto } from "../../../infrastructure/types/config/dto/CookiesConsentDto";

export const AcceptedCookiesConsentValue = "true";

export const NecessaryCookieName = "ga_cookie_consent";
export const AnalysisCookieName = "ga_analysis_consent";

export class CookiesConsentConfig {
  @observable cookiesConsent: CookiesConsentDto = {
    consentText: "",
    privacyPolicyLink: "",
    privacyPolicyLinkTitle: "",
  };

  @observable selectedCookies: Record<string, boolean> = {
    [NecessaryCookieName]: true,
    [AnalysisCookieName]: false,
  };

  constructor(private gtm: IGtmService) {
    makeObservable(this);
  }

  @action.bound
  toggleCookie(cookieName: string) {
    this.selectedCookies[cookieName] = !this.selectedCookies[cookieName];
  }

  @action.bound
  acceptAllCookies() {
    Object.keys(this.selectedCookies).forEach(cookieName => {
      Cookies.set(cookieName, "true");
    });
    this.gtm.pushCookieConsentChanged();
  }

  @action.bound
  acceptSpecificCookies() {
    Object.keys(this.selectedCookies).forEach(cookieName => {
      Cookies.set(cookieName, this.selectedCookies[cookieName] ? "true" : "false");
    });
    this.gtm.pushCookieConsentChanged();
  }

  @action.bound
  setCookiesConsent(cookiesConsent: CookiesConsentDto) {
    this.cookiesConsent = cookiesConsent;
  }
}
