import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { AcceptInvitationRequest } from "../../../../infrastructure/types/api/invitation/AcceptInvitationRequest";

export class InvitationStore {
  constructor(private httpClient: HttpClient) {}

  public async acceptInvitation(userEmail: string, returnUrl: string) {
    const acceptInvitationResult = await this.httpClient.send(new AcceptInvitationRequest({ userEmail, returnUrl }));

    return acceptInvitationResult;
  }
}
