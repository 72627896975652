//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CourseStatsDto } from '../../courseassignments/dto/CourseStatsDto';
import { IApiRequest } from '../../IApiRequest';

export class GetCourseAssignmentStatsRequest extends IApiRequest<CourseStatsDto[]>
{
	public $type = 'GetCourseAssignmentStatsRequest';
	constructor(public data: { companyId: string, courseIds: string[] }) { super(); }
}
