import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { MouseEventHandler } from "react";
import { Command } from "../../infrastructure/commands/Command";

export type ButtonType = "solid" | "outline" | "circle" | "clear";

export type ButtonVariant = "primary" | "secondary" | "tertiary";

export type ButtonSize = "xSmall" | "small" | "medium" | "large" | "xLarge";

export type HtmlButtonType = "button" | "submit" | "reset";

export interface DefaultButtonProps {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: ButtonType;
  variant?: ButtonVariant;
  htmltype?: HtmlButtonType;
  size?: ButtonSize;
  className?: string;
  tabIndex?: number;
  title?: string;
  children: React.ReactNode;
}

type ButtonProps = DefaultButtonProps &
  Pick<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    "aria-selected" | "aria-controls" | "aria-expanded" | "role" | "aria-haspopup" | "aria-describedby"
  >;

interface CommandButtonProps extends Omit<ButtonProps, "onClick" | "disabled"> {
  command: Command | (() => void) | (() => Promise<void>);
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    onClick,
    type = "solid",
    variant = "primary",
    htmltype = "button",
    size = "medium",
    className,
    disabled,
    tabIndex,
    title,
  } = props;
  return (
    <button
      ref={ref}
      {...props}
      // eslint-disable-next-line react/button-has-type
      type={htmltype}
      onClick={onClick}
      className={classNames("Button", `Button--${type}`, `Button--${variant}`, `Button--${size}`, className)}
      disabled={disabled}
      tabIndex={tabIndex}
      title={title}
    >
      {children}
    </button>
  );
});

export const CommandButton = observer(
  React.forwardRef<HTMLButtonElement, CommandButtonProps>((props: CommandButtonProps, ref) => {
    const { command, tabIndex, ...otherProps } = props;
    return (
      <Button
        {...otherProps}
        ref={ref}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
          if (typeof command === "function") {
            return command();
          }
          return command.execute();
        }}
        tabIndex={tabIndex}
        disabled={typeof command === "function" ? false : !command.isEnabled}
      />
    );
  })
);
