//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { TaskDto } from '../../test/dto/TaskDto';
import { IApiRequest } from '../../IApiRequest';

export class GetTaskPreviewRequest extends IApiRequest<TaskDto>
{
	public $type = 'GetTaskPreviewRequest';
	constructor(public data: { id: string, revision: string, taskType: number, lang?: string }) { super(); }
}
