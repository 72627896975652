import { action } from "mobx";
import { HttpClient } from "../../infrastructure/api/HttpClient";
import { FilterValueDto } from "../../infrastructure/types/api/reports/FilterValueDto";
import { GetCourseAssignmentsReportRequest } from "../../infrastructure/types/api/reports/GetCourseAssignmentsReportRequest";
import { CompanyId } from "../company/CompanyId";

export class ReportStore {
  constructor(private httpClient: HttpClient) {}

  @action.bound
  public async getCourseAssignmentsReport(
    companyId: CompanyId,
    years: number[],
    groups: FilterValueDto,
    users: FilterValueDto,
    courses: FilterValueDto
  ) {
    const report = this.loadCourseAssignmentsReport(companyId, years, groups, users, courses);

    return report;
  }

  @action.bound
  private async loadCourseAssignmentsReport(
    companyId: CompanyId,
    years: number[],
    groups: FilterValueDto,
    users: FilterValueDto,
    courses: FilterValueDto
  ) {
    const response = await this.httpClient.send(
      new GetCourseAssignmentsReportRequest({ companyId: companyId.value, years, groups, users, courses }),
      undefined,
      "blob"
    );
    return response;
  }
}
