import sumBy from "lodash/sumBy";
import { makeObservable, observable, computed } from "mobx";
import { CourseContentId } from "../../../infrastructure/types/content/model/CourseContentId";
import { CourseContentDto } from "../../../infrastructure/types/coursecontents/dto/CourseContentDto";
import { CourseContentInfoDto } from "../../../infrastructure/types/coursecontents/dto/CourseContentInfoDto";
import { CourseModeDto } from "../../../infrastructure/types/shared/dto/CourseModeDto";
import { ImageDto } from "../../../infrastructure/types/shared/dto/ImageDto";
import { getCourseElementsCount } from "../utils/getCourseElementsCount";
import { CourseTest } from "./CourseTest";
import { ModuleDetailed, ModuleInfo } from "./Module";

export class CourseInfo {
  @observable id;

  @observable slug;

  @observable title;

  @observable introduction;

  @observable image;

  @observable courseTest?: CourseTest;

  @observable modules: ModuleInfo[];

  readonly _type = "courseInfo";

  @computed get courseDuration() {
    return sumBy(this.modules, m => m.duration);
  }

  @computed get elementsCount() {
    return getCourseElementsCount(this.modules, this.courseTest);
  }

  constructor(dto: CourseContentInfoDto) {
    makeObservable(this);
    this.id = dto.id;
    this.slug = dto.slug;
    this.title = dto.title;
    this.image = dto.image;
    this.introduction = dto.introduction;
    this.modules = dto.modules.map(m => new ModuleInfo(this, m));
    this.courseTest = dto.courseTest && new CourseTest(this, dto.courseTest);
  }
}

export class CourseDetailed {
  @observable id: CourseContentId;

  @observable slug: string;

  @observable title: string;

  @observable introduction: string;

  @observable image: ImageDto;

  @observable courseTest?: CourseTest;

  @observable modules: ModuleDetailed[];

  @observable mode: CourseModeDto;

  readonly _type = "courseDetailed";

  @computed get courseDuration() {
    return sumBy(this.modules, m => m.duration);
  }

  @computed get elementsCount() {
    return getCourseElementsCount(this.modules, this.courseTest);
  }

  @computed get isExam() {
    return this.mode === CourseModeDto.Exam;
  }

  constructor(dto: CourseContentDto) {
    makeObservable(this);
    this.id = dto.id;
    this.slug = dto.slug;
    this.title = dto.title;
    this.introduction = dto.introduction;
    this.image = dto.image;
    this.modules = dto.modules.map(m => new ModuleDetailed(this, m));
    this.courseTest = dto.courseTest && new CourseTest(this, dto.courseTest);
    this.mode = dto.mode;
  }
}

export type ContentCourse = CourseInfo | CourseDetailed;
