//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CompanyMembershipCreatedResultDto } from '../../users/dto/CompanyMembershipCreatedResultDto';
import { IApiRequest } from '../../IApiRequest';

export class AddMemberRequest extends IApiRequest<CompanyMembershipCreatedResultDto>
{
	public $type = 'AddMemberRequest';
	constructor(public data: { userEmail: string, companyId: string }) { super(); }
}
