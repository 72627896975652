import axios, { CancelTokenSource } from "axios";
import { SearchUsersRequest } from "infrastructure/types/api/users/SearchUsersRequest";
import { UserAndGroupTypeDto } from "infrastructure/types/users/dto/UserAndGroupTypeDto";
import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { UserAndGroupItemDto } from "../../../../infrastructure/types/users/dto/UserAndGroupItemDto";
import { PaginationOptions, SearchPhrase } from "../../../../ui/loadMoreList/PaginatedList";
import { CompanyId } from "../../../company/CompanyId";

export class UserItem {
  public id: string;

  public firstName?: string;

  public lastName?: string;

  public email?: string;

  public readonly _type = UserAndGroupTypeDto.User;

  constructor(dto: UserAndGroupItemDto) {
    this.id = dto.id;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.email = dto.email;
  }
}

export class UserSearchStore {
  cancelTokenSource?: CancelTokenSource;

  constructor(private httpClient: HttpClient) {}

  public async getItems(companyId: CompanyId, searchPhrase: SearchPhrase, options: PaginationOptions) {
    const result = await this.loadItems(companyId, searchPhrase, options);

    const { results, total } = result;

    const items = results.map(u => new UserItem(u));

    return { items, total };
  }

  private async loadItems(companyId: CompanyId, searchPhrase: SearchPhrase, options: PaginationOptions) {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("Canceled because of a new request.");
    }

    this.cancelTokenSource = axios.CancelToken.source();

    const response = await this.httpClient.send(
      new SearchUsersRequest({ companyId: companyId.value, searchPhrase, ...options }),
      this.cancelTokenSource.token
    );

    this.cancelTokenSource = undefined;

    return response;
  }
}
