import React, { ReactNode } from "react";
import { Route } from "react-router-dom";
import { useAppUser } from "../../application/models/appUser/UserContext";
import { hasUserAccess } from "../authorization/Authorization";
import { PageForbiddenError } from "../errors/PageForbiddenError";
import { getParams } from "../paths/Hooks";
import { Path, PathParams } from "../paths/Paths";
import { getLoginPath } from "./link/LoginLink";

type TypedRouteProps<P extends Path> = {
  path: P;
  exact?: boolean;
  render: (params: PathParams<P>) => ReactNode;
};

/**
 * Type-safe version of `react-router-dom/Route`.
 */
export const TypedRoute = <P extends Path>({ path, render, exact }: TypedRouteProps<P>) => {
  const user = useAppUser();
  const hasUserAccessToRoute = hasUserAccess(user, path);

  // https://dev.to/mxdavis/redirecting-to-an-external-url-within-react-router-3nf1
  return (
    <Route
      exact={exact}
      path={path}
      render={({ match }) => {
        if (hasUserAccessToRoute) {
          return render(getParams(match, path));
        }

        if (user._type === "anonymous") {
          window.location.assign(getLoginPath({ to: path, params: getParams(match, path) }));
          return null;
        }

        throw new PageForbiddenError();
      }}
    />
  );
};
