import classNames from "classnames";
import * as React from "react";

interface LoaderProps {
  className?: string;
}

export const Loader: React.FC<LoaderProps> = ({ className }) => {
  const loading = "Laster...";
  return (
    <div className={classNames("Loader", className)}>
      <div className="Loader__inner" />
      <span className="visuallyHidden">{loading}</span>
    </div>
  );
};
