import TagManager from "react-gtm-module";

export interface IGtmService {
  pushCookieConsentChanged(): void;
}

interface DataLayerModel {
  event: string;
  eventAction?: string;
  eventLabel?: string;
  params?: GoogleTagManagerProperties;
}

interface GoogleTagManagerProperties {
  [key: string]: string | number | boolean | GoogleTagManagerProperties | null;
}

export class GtmService implements IGtmService {
  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const containerId = (window as any).googleTagManagerContainerId;
    if (containerId) {
      TagManager.initialize({ gtmId: containerId });
    }
  }

  pushCookieConsentChanged() {
    this.push({ event: "cookieConsentChanged" });
  }

  private push = (data: DataLayerModel) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { dataLayer } = window as any;
    dataLayer && dataLayer.push(data);
  };
}
