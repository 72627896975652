//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CourseWithPriceOptionDto } from '../../courseslist/dto/CourseWithPriceOptionDto';
import { IApiRequest } from '../../IApiRequest';

export class GetBuyCourseDetailsRequest extends IApiRequest<CourseWithPriceOptionDto>
{
	public $type = 'GetBuyCourseDetailsRequest';
	constructor(public data: { slug: string, lang?: string }) { super(); }
}
