//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { AcceptInvitationResultDto } from '../../invitation/dto/AcceptInvitationResultDto';
import { IApiRequest } from '../../IApiRequest';

export class AcceptInvitationRequest extends IApiRequest<AcceptInvitationResultDto>
{
	public $type = 'AcceptInvitationRequest';
	constructor(public data: { userEmail: string, returnUrl: string, companyId?: string }) { super(); }
}
