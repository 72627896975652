//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GroupMembershipsCreatedResultDto } from '../../group/dto/GroupMembershipsCreatedResultDto';
import { IApiRequest } from '../../IApiRequest';

export class AddMembersToGroupRequest extends IApiRequest<GroupMembershipsCreatedResultDto>
{
	public $type = 'AddMembersToGroupRequest';
	constructor(public data: { groupId: string, companyId: string, userIds: string[] }) { super(); }
}
