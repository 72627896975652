//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CourseAssignmentDto } from '../../courseassignments/dto/CourseAssignmentDto';
import { IApiRequest } from '../../IApiRequest';

export class GetUserCourseAssignmentsRequest extends IApiRequest<CourseAssignmentDto[]>
{
	public $type = 'GetUserCourseAssignmentsRequest';
	constructor(public data: { userId: string, companyId?: string }) { super(); }
}
