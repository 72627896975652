//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { TestResultDto } from '../../test/dto/TestResultDto';
import { IApiRequest } from '../../IApiRequest';
import { UserTaskAnswerDto } from '../../test/dto/UserTaskAnswerDto';
import { ModuleContentId } from '../../content/model/ModuleContentId';

export class SubmitModuleTestRequest extends IApiRequest<TestResultDto>
{
	public $type = 'SubmitModuleTestRequest';
	constructor(public data: { userTaskAnswers: UserTaskAnswerDto[], courseAssignmentId: string, moduleContentId: ModuleContentId, useDossier?: boolean }) { super(); }
}
