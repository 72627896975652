//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GroupLeadershipsCreatedResultDto } from '../../group/dto/GroupLeadershipsCreatedResultDto';
import { IApiRequest } from '../../IApiRequest';

export class AddLeadersToGroupRequest extends IApiRequest<GroupLeadershipsCreatedResultDto>
{
	public $type = 'AddLeadersToGroupRequest';
	constructor(public data: { groupId: string, companyId: string, userIds: string[] }) { super(); }
}
