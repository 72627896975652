import React from "react";
import { useLocation } from "react-router";
import { InvitationStore } from "../../../domain/courseAssigning/stores/invitationStore/InvitationStore";
import { Loader } from "../../../ui/loader/Loader";

interface InvitationProps {
  invitationStore: InvitationStore;
}

export const LazyInvitation: React.FC<InvitationProps> = ({ invitationStore }) => {
  const { search } = useLocation();
  const Lazy = React.lazy(async () => {
    const searchParams = new URLSearchParams(search);
    const userEmail = searchParams.get("userEmail");

    if (!userEmail) {
      throw new Error(`No 'userEmail' query string parameter in request '${window.location.href}'`);
    }

    const decodedUserEmail = decodeURIComponent(userEmail);
    const result = await invitationStore.acceptInvitation(decodedUserEmail, window.location.href);

    const redirectToUrl = (url: string) => {
      if (!url) {
        throw new Error("Accept invitation result redirect url is empty");
      }
      window.location.href = url;
    };

    redirectToUrl(result.url);

    return {
      default: () => <Loader />,
    };
  });

  return <Lazy />;
};
