import { observable } from "mobx";
import { GroupCourseAssignmentWithStatsDto } from "../../../infrastructure/types/group/dto/GroupCourseAssignmentWithStatsDto";
import { CourseAssignmentStats } from "../../course/models/CourseStats";

export class GroupCourseAssignmentWithStats {
  @observable id: string;

  @observable courseId: string;

  @observable sanityCourseId: string;

  @observable activeTo: string;

  @observable stats: CourseAssignmentStats;

  constructor(groupCourseAssignmentWithStats: GroupCourseAssignmentWithStatsDto) {
    this.id = groupCourseAssignmentWithStats.id;
    this.courseId = groupCourseAssignmentWithStats.courseId;
    this.sanityCourseId = groupCourseAssignmentWithStats.sanityCourseId;
    this.activeTo = groupCourseAssignmentWithStats.activeTo;
    this.stats = new CourseAssignmentStats(groupCourseAssignmentWithStats.stats);
  }
}
