//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CourseContentInfoDto } from '../../coursecontents/dto/CourseContentInfoDto';
import { IApiRequest } from '../../IApiRequest';

export class GetCourseContentInfoRequest extends IApiRequest<CourseContentInfoDto>
{
	public $type = 'GetCourseContentInfoRequest';
	constructor(public data: { courseDomainId: string, companyId: string, lang?: string }) { super(); }
}
