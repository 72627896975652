//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { UserDto } from '../../shared/dto/UserDto';
import { IApiRequest } from '../../IApiRequest';

export class GetAppUserRequest extends IApiRequest<UserDto | null>
{
	public $type = 'GetAppUserRequest';
	constructor() { super(); }
}
