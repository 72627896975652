import { CompanyId } from "domain/company/CompanyId";
import { LoggedInAppUser } from "application/models/appUser/AppUser";
import { action, computed, makeObservable, observable } from "mobx";
import { getGroupInitials } from "utils/groupUtils";
import { GroupStatsDto } from "../../../infrastructure/types/group/dto/GroupStatsDto";
import { GroupStore } from "../stores/groupStore/GroupStore";

export class Group {
  @observable public id: string;

  @observable public name: string;

  @observable public membersCount: number;

  @observable public leaderIds: string[];

  @observable public activeCoursesCount: number;

  @computed get groupInitials() {
    return getGroupInitials(this.name);
  }

  readonly _type = "group";

  constructor(dto: GroupStatsDto, private companyId: CompanyId, private groupStore: GroupStore) {
    makeObservable(this);
    this.id = dto.id;
    this.name = dto.name;
    this.membersCount = dto.membersCount;
    this.leaderIds = dto.leaderIds;
    this.activeCoursesCount = dto.activeCoursesCount;
  }

  @action.bound
  public async updateToNewest() {
    const dto = await this.groupStore.loadGroup(this.id, this.companyId);

    this.id = dto.id;
    this.name = dto.name;
    this.membersCount = dto.membersCount;
    this.leaderIds = dto.leaderIds;
    this.activeCoursesCount = dto.activeCoursesCount;
  }

  @action.bound
  public async rename(name: string) {
    const trimmedGroupName = name.trim();
    await this.groupStore.renameGroup(this.id, trimmedGroupName, this.companyId);
    await this.updateToNewest();
  }

  @action.bound
  public async remove() {
    await this.groupStore.removeGroup(this.id, this.companyId);
  }

  public hasUserGroupManagementRights(user: LoggedInAppUser) {
    return user.isAdmin || this.leaderIds.some(id => id === user.id);
  }
}
