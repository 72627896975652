//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GroupCourseAssignmentWithStatsDto } from '../../group/dto/GroupCourseAssignmentWithStatsDto';
import { IApiRequest } from '../../IApiRequest';

export class GetGroupCourseAssignmentsWithStatsRequest extends IApiRequest<GroupCourseAssignmentWithStatsDto[]>
{
	public $type = 'GetGroupCourseAssignmentsWithStatsRequest';
	constructor(public data: { groupId: string }) { super(); }
}
