import classNames from "classnames";
import { ReactNode } from "react";
import { ButtonSize, ButtonType, ButtonVariant } from "../../../ui/button/Button";

export type LinkType = "primary" | "secondary" | "button" | "clear";

export type LinkSize = "xLarge" | "large" | "medium" | "small" | "xSmall" | "inherit";

export interface LinkPropsBase {
  type?: LinkType;
  buttonType?: ButtonType;
  buttonVariant?: ButtonVariant;
  size?: LinkSize;
  buttonSize?: ButtonSize;
  children: ReactNode;
  tabIndex?: number;
  className?: string;
}

export const getLinkClassNames = (
  type: LinkType,
  buttonType: ButtonType,
  buttonVariant: ButtonVariant,
  size: LinkSize,
  buttonSize: ButtonSize,
  className?: string
) => {
  if (type === "button") {
    return classNames(
      "Link",
      "Link--clear",
      "Button",
      `Button--${buttonType}`,
      `Button--${buttonVariant}`,
      `Button--${buttonSize}`,
      className
    );
  }

  return classNames("Link", `Link--${type}`, `Link--${size}`, className);
};
