import { action, makeObservable, observable } from "mobx";
import { PortableTextDto } from "../../../infrastructure/types/shared/dto/PortableTextDto";
import { TaskDto } from "../../../infrastructure/types/test/dto/TaskDto";
import { MultipleAnswerQuestion, SingleAnswerQuestion } from "../components/task/taskUtils";
import { OptionWithStatus } from "./OptionWithStatus";

export type TaskStatus = "default" | "readOnly";

export type Answer = { id: string; content: PortableTextDto };
export type Question = { id: string; content: PortableTextDto };

export type Answers = Answer[];
export type Questions = Question[];

export type MatchTaskValue = (SingleAnswerQuestion | MultipleAnswerQuestion)[];

export class TaskWithStatus {
  @observable id: string;

  @observable status: TaskStatus = "default";

  @observable optionsWithStatus: Record<string, OptionWithStatus>;

  @observable correctInputAnswers: string;

  @observable isMobile?: boolean = window.innerWidth <= 870;

  constructor(public task: TaskDto) {
    makeObservable(this);
    window.addEventListener("resize", this.updateIsMobile);
    const optionsWithStatus: Record<string, OptionWithStatus> = {};
    const correctAnswers = "";

    if (task.choiceTask) {
      task.choiceTask.options.forEach(option => {
        optionsWithStatus[option.id] = new OptionWithStatus(option);
      });
    }

    if (task.matchTask) {
      task.matchTask.questions.forEach(question => {
        task.matchTask?.answers.forEach(answer => {
          optionsWithStatus[`${task.id}:${question.id}:${answer.id}`] = new OptionWithStatus(question);
        });
      });
    }

    if (task.inputTask) {
      optionsWithStatus[task.inputTask.id] = new OptionWithStatus(task.inputTask);
    }

    this.optionsWithStatus = optionsWithStatus;
    this.id = task.id;
    this.correctInputAnswers = correctAnswers;
  }

  @action.bound
  updateIsMobile = () => {
    this.isMobile = window.innerWidth <= 870;
  };

  @action.bound
  changeStatus = (status: TaskStatus) => {
    this.status = status;
  };

  @action.bound
  setInputTaskAnswers = (answers: string[]) => {
    this.correctInputAnswers = answers.join("/");
  };

  @action.bound
  reset = () => {
    this.changeStatus("default");
    this.setInputTaskAnswers([]);
    Object.keys(this.optionsWithStatus).forEach(key => {
      const answer = this.optionsWithStatus[key];
      answer.reset();
    });
  };

  dispose = () => {
    window.removeEventListener("resize", this.updateIsMobile);
  };
}
