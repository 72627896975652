import { HttpCustomError } from "infrastructure/api/HttpCustomError";
import { ExternalLink } from "infrastructure/components/link/ExternalLink";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { assertUnreachable } from "utils/utils";
import { Button } from "../../ui/button/Button";
import { Loader } from "../../ui/loader/Loader";
import { HttpError } from "../api/HttpError";
import { getLoginPath } from "../components/link/LoginLink";
import { BadRequestResultTypeDto } from "../types/api/error/BadRequestResultTypeDto";
import { RouteConfiguration } from "../types/routes/RouteConfiguration";
import { CheckoutLoginRequiredError } from "./CheckoutLoginRequiredError";
import { LoginRequiredError } from "./LoginRequiredError";
import { PageForbiddenError } from "./PageForbiddenError";
import { PageNotFoundError } from "./PageNotFoundError";

interface ErrorViewProps {
  error: Error;
}

export const ErrorView: React.FC<ErrorViewProps> = ({ error }) => {
  const { t } = useTranslation();

  const [isLoadingIndicatorVisible, setIsLoadingIndicatorVisible] = React.useState(true);

  React.useEffect(() => {
    if (error instanceof LoginRequiredError) {
      location.assign(getLoginPath());
    } else if (error instanceof CheckoutLoginRequiredError) {
      if (error.type === "course") {
        location.assign(
          getLoginPath({ to: "/landingsside/kurs/:courseSlug/kjop", params: { courseSlug: error.getSlug() } })
        );
      } else {
        location.assign(
          getLoginPath({
            to: "/landingsside/kurspakker/:packageSlug/kjop",
            params: { packageSlug: error.getSlug() },
          })
        );
      }
    } else {
      setIsLoadingIndicatorVisible(false);
    }
  }, [error]);

  const getErrorInfo = (e: Error) => {
    if (e instanceof HttpCustomError) {
      switch (e.type) {
        case BadRequestResultTypeDto.MembershipNotActive:
          return {
            title: t("invitationExpiredHeading"),
            description: t("invitationExpiredDescription"),
            displayLogoutButton: true,
          };
        case BadRequestResultTypeDto.NoMembership:
          return {
            title: t("noMembershipHeading"),
            description: t("noMembershipDescription"),
            displayLogoutButton: true,
          };
        case BadRequestResultTypeDto.NoTibetPublication:
          return {
            title: t("noTibetPublicationHeading"),
            description: t("noTibetPublicationDescription"),
            displayLogoutButton: false,
          };
        case BadRequestResultTypeDto.NoLicenseInTibet:
          return {
            title: t("noLicenseToSendWithInvitationHeading"),
            description: t("noLicenseToSendWithInvitationDescription"),
          };
        case BadRequestResultTypeDto.CompanyUserNotAllowed:
          return {
            title: t("companyUserNotAllowedToBuyCourseTitle"),
            description: t("companyUserNotAllowedToBuyCourseDescription"),
          };

        case BadRequestResultTypeDto.UserRejectedInvitation:
          return {
            title: "Avslått invitasjon",
            description: "Din bruker har avslått invitasjonen til bedriften",
            displayLogoutButton: false,
          };
        case BadRequestResultTypeDto.NoDossierKey:
          return {
            title: "Mangler integrasjon med Dossier",
            description: "Integrasjonen med Dossier er ikke skrudd på",
            displayLogoutButton: false,
          };
        default:
          assertUnreachable(e.type);
      }
    }
    if (e instanceof HttpError) {
      switch (e.code) {
        case PageForbiddenError.code:
          return {
            title: e.code.toString(),
            description: t("unauthorizedViewMessage"),
            displayDashboardButton: true,
            displayLogoutButton: true,
          };
        case PageNotFoundError.code:
          return { title: e.code.toString(), description: t("notFoundErrorMessage") };
        default:
          return { title: e.code.toString(), description: t("unexpectedErrorMessage"), displayLogoutButton: true };
      }
    }

    return { title: t("unexpectedErrorMessage"), description: undefined, displayLogoutButton: true };
  };

  const { title, description, displayLogoutButton, displayDashboardButton } = getErrorInfo(error);

  return isLoadingIndicatorVisible ? (
    <Loader />
  ) : (
    <article className="Error">
      <div className="Error__content">
        <div className="Error__info">
          <h1 className="Error__title">Feil: {title}</h1>
          {description && (
            <h2 className="Error__description">
              Du har ikke tilgang til denne siden. Sjekk at du er logget inn. Løser ikke problemet seg, kontakt{" "}
              <a style={{ textDecoration: "underline" }} href="mailto:support@gyldendal.no">
                support@gyldendal.no
              </a>
              , så hjelper vi deg.
            </h2>
          )}
          {displayLogoutButton && (
            <ExternalLink
              className="Error_logoutButton"
              href={RouteConfiguration.Logout}
              type="button"
              buttonType="solid"
              buttonVariant="secondary"
              buttonSize="medium"
            >
              {t("logout")}
            </ExternalLink>
          )}
          {displayDashboardButton && (
            <NavLink to="/">
              <Button size="xLarge" className="Error__dashboardButton">
                Gå tilbake til Qudos
              </Button>
            </NavLink>
          )}
        </div>
      </div>
    </article>
  );
};
