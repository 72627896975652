//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CheckoutDto } from '../../landing/dto/CheckoutDto';
import { IApiRequest } from '../../IApiRequest';
import { CheckoutUrlsDto } from '../../landing/dto/CheckoutUrlsDto';

export class CheckoutCourseRequest extends IApiRequest<CheckoutDto>
{
	public $type = 'CheckoutCourseRequest';
	constructor(public data: { courseSlug: string, checkoutUrls: CheckoutUrlsDto }) { super(); }
}
