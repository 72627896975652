/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from "axios";
import axiosInherit from "axios-inherit";
import { CheckoutLoginRequiredError } from "infrastructure/errors/CheckoutLoginRequiredError";
import { CheckoutCourseRequest } from "infrastructure/types/api/landing/CheckoutCourseRequest";
import { CheckoutPackageRequest } from "infrastructure/types/api/landing/CheckoutPackageRequest";
import { LoginRequiredError } from "../errors/LoginRequiredError";

const generateLoginRequiredError = (err: AxiosError) => {
  const checkoutCourseUrl = new CheckoutCourseRequest({} as any).$type.replace("Request", "");
  const checkoutPackageUrl = new CheckoutPackageRequest({} as any).$type.replace("Request", "");

  if (err.config.url === checkoutCourseUrl) {
    return new CheckoutLoginRequiredError(err.message, err.config.data, "course");
  }

  if (err.config.url === checkoutPackageUrl) {
    return new CheckoutLoginRequiredError(err.message, err.config.data, "package");
  }

  return new LoginRequiredError(err.message);
};

export const initAxiosGlobalInterceptors = () => {
  // This makes sure global interceptors don't get overriden when defining interceptor on local instances
  // See issue https://github.com/axios/axios/issues/993
  // N.B. this _has_ to be called before local instances are created, so be careful.
  axiosInherit(axios);
  axios.interceptors.response.use(
    ok => ok,
    err => {
      switch (err.response?.status) {
        case 401:
          return Promise.reject(generateLoginRequiredError(err));
        default:
          return Promise.reject(err);
      }
    }
  );
};
