//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IndividualCourseAssignmentDto } from '../../courseassignments/dto/IndividualCourseAssignmentDto';
import { PagedResultDto } from '../../shared/dto/PagedResultDto';
import { IApiRequest } from '../../IApiRequest';

export class GetCourseAssignmentsForGroupMembersRequest extends IApiRequest<PagedResultDto<IndividualCourseAssignmentDto>>
{
	public $type = 'GetCourseAssignmentsForGroupMembersRequest';
	constructor(public data: { groupCourseAssignmentId: string, companyId: string, skip: number, take: number }) { super(); }
}
