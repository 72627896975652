import { GroupLeaderDto } from "infrastructure/types/group/dto/GroupLeaderDto";
import { UserRoleDto } from "infrastructure/types/shared/dto/UserRoleDto";
import { computed, makeObservable, observable } from "mobx";
import { getUserDisplayName, getUserInitials } from "../../../utils/userUtils";

export class GroupLeader {
  @observable id: string;

  @observable userId: string;

  @observable userFirstName?: string;

  @observable userLastName?: string;

  @observable userEmail: string;

  @observable role: UserRoleDto;

  constructor(dto: GroupLeaderDto) {
    makeObservable(this);
    this.id = dto.id;
    this.userId = dto.userId;
    this.userFirstName = dto.userFirstName;
    this.userLastName = dto.userLastName;
    this.userEmail = dto.userEmail;
    this.role = dto.userRole;
  }

  @computed get displayName() {
    return getUserDisplayName(this.userEmail, this.userFirstName, this.userLastName);
  }

  @computed get initials() {
    return getUserInitials(this.userEmail, this.userFirstName, this.userLastName);
  }
}
