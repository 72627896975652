import { ModuleContentId } from "../../../infrastructure/types/content/model/ModuleContentId";
import { TestContentId } from "../../../infrastructure/types/content/model/TestContentId";

interface Module {
  id: ModuleContentId;
}

interface CourseTest {
  testId: TestContentId;
}

export const getCourseElementsCount = (modules: Module[], courseTest?: CourseTest) => {
  return modules.length + (courseTest ? 1 : 0);
};
