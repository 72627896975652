import { CompanyId } from "domain/company/CompanyId";
import { GetAssignableCourseDomainsRequest } from "infrastructure/types/api/courses/GetAssignableCourseDomainsRequest";
import { GetCourseDomainsRequest } from "infrastructure/types/api/courses/GetCourseDomainsRequest";
import { makeObservable, observable } from "mobx";
import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { GetCourseDomainBySlugRequest } from "../../../../infrastructure/types/api/courses/GetCourseDomainBySlugRequest";
import { GetCourseDomainRequest } from "../../../../infrastructure/types/api/courses/GetCourseDomainRequest";
import { CourseDomain } from "../../models/CourseDomain";

export class CourseDomainStore {
  @observable courses: CourseDomain[] = [];

  @observable selfAssignableCourses: CourseDomain[] = [];

  constructor(private httpClient: HttpClient) {
    makeObservable(this);
  }

  public async getAssignableCourses(companyId: CompanyId) {
    await this.loadAssignableCourses(companyId);

    return this.courses;
  }

  public async getSelfAssignableCourses(companyId: CompanyId) {
    await this.loadSelfAssignableCourses(companyId);

    return this.selfAssignableCourses;
  }

  public async getCourses(ids: string[]) {
    await this.loadCourses(ids);

    return this.courses;
  }

  public async loadCourses(ids: string[]) {
    const response = await this.httpClient.send(new GetCourseDomainsRequest({ ids }));
    const loadedCourses = response.map(dto => new CourseDomain(dto));
    this.courses = loadedCourses;
  }

  public async loadCourseBySlug(slug: string) {
    const response = await this.httpClient.send(new GetCourseDomainBySlugRequest({ slug }));

    const loadedCourse = new CourseDomain(response);
    this.courses.push(loadedCourse);
    return loadedCourse;
  }

  public async loadCourse(id: string) {
    const response = await this.httpClient.send(new GetCourseDomainRequest({ id }));
    const loadedCourse = new CourseDomain(response);
    this.courses.push(loadedCourse);
    return loadedCourse;
  }

  private async loadAssignableCourses(companyId: CompanyId) {
    const response = await this.httpClient.send(new GetAssignableCourseDomainsRequest({ companyId: companyId.value }));
    const loadedCourses = response.map(dto => new CourseDomain(dto));
    this.courses = loadedCourses;
  }

  private async loadSelfAssignableCourses(companyId: CompanyId) {
    const response = await this.httpClient.send(new GetAssignableCourseDomainsRequest({ companyId: companyId.value }));
    const loadedCourses = response.map(dto => new CourseDomain(dto));
    this.selfAssignableCourses = loadedCourses;
  }
}
