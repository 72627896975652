import { CompanyId } from "domain/company/CompanyId";
import axios, { CancelTokenSource } from "axios";
import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { SearchUsersAndGroupsRequest } from "../../../../infrastructure/types/api/users/SearchUsersAndGroupsRequest";
import { SearchTypeDto } from "../../../../infrastructure/types/users/dto/SearchTypeDto";
import { UserAndGroupItemDto } from "../../../../infrastructure/types/users/dto/UserAndGroupItemDto";
import { UserAndGroupTypeDto } from "../../../../infrastructure/types/users/dto/UserAndGroupTypeDto";
import { PaginationOptions, SearchPhrase } from "../../../../ui/loadMoreList/PaginatedList";
import { LoadMoreButtonItem } from "../../models/LoadMoreButtonItem";

export type LoadedSearchedItem = UserAndGroupItem | LoadMoreButtonItem;

export class UserAndGroupItem {
  public id: string;

  public _type: UserAndGroupTypeDto;

  public firstName?: string;

  public lastName?: string;

  public email?: string;

  public name?: string;

  constructor(dto: UserAndGroupItemDto) {
    this.id = dto.id;
    this._type = dto.type;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.email = dto.email;
    this.name = dto.name;
  }
}

export class UserAndGroupSearchStore {
  cancelTokenSource?: CancelTokenSource;

  constructor(private httpClient: HttpClient) {}

  public async getItems(
    searchType: SearchTypeDto,
    companyId: CompanyId,
    searchPhrase: SearchPhrase,
    options: PaginationOptions
  ) {
    const result = await this.loadItems(searchType, companyId, searchPhrase, options);

    const { results, total } = result;

    const items = results.map(u => new UserAndGroupItem(u));

    return { items, total };
  }

  private async loadItems(
    searchType: SearchTypeDto,
    companyId: CompanyId,
    searchPhrase: string,
    options: PaginationOptions
  ) {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("Canceled because of a new request.");
    }

    this.cancelTokenSource = axios.CancelToken.source();

    const response = await this.httpClient.send(
      new SearchUsersAndGroupsRequest({ searchType, companyId: companyId.value, searchPhrase, ...options }),
      this.cancelTokenSource.token
    );

    this.cancelTokenSource = undefined;

    return response;
  }
}
