import { action, computed, makeObservable, observable } from "mobx";
import { CompanyMembershipStatusDto } from "../../../infrastructure/types/company/dto/CompanyMembershipStatusDto";
import { CompanyUserDto } from "../../../infrastructure/types/company/dto/CompanyUserDto";
import { UserRoleDto } from "../../../infrastructure/types/shared/dto/UserRoleDto";
import { getUserDisplayName, getUserInitials } from "../../../utils/userUtils";
import { CompanyStore } from "../stores/companyStore/CompanyStore";

export class CompanyUser {
  @observable id: string;

  @observable firstName?: string;

  @observable lastName?: string;

  @observable email: string;

  @observable role: UserRoleDto;

  @observable status: CompanyMembershipStatusDto;

  @observable isActive: boolean;

  @observable phone?: string;

  @observable companyMembershipCreatedAt: string;

  @computed get initials() {
    return getUserInitials(this.email, this.firstName, this.lastName);
  }

  @computed get displayName() {
    return getUserDisplayName(this.email, this.firstName, this.lastName);
  }

  @computed get isManager() {
    return this.role === UserRoleDto.Manager;
  }

  @computed get isAdmin() {
    return this.role === UserRoleDto.Admin;
  }

  constructor(public companyStore: CompanyStore, dto: CompanyUserDto) {
    makeObservable(this);
    this.id = dto.id;
    this.firstName = dto.userFirstName;
    this.lastName = dto.userLastName;
    this.email = dto.userEmail;
    this.role = dto.userRole;
    this.status = dto.status;
    this.isActive = dto.isActive;
    this.companyMembershipCreatedAt = dto.companyMembershipCreatedAt;
  }

  @action.bound
  async assignManagerRole() {
    await this.companyStore.assignManagerRole(this.id);
    this.role = UserRoleDto.Manager;
  }

  @action.bound
  async revokeManagerRole() {
    await this.companyStore.revokeManagerRole(this.id);
    this.role = UserRoleDto.Member;
  }

  @action.bound
  async addConnection(managerId: string) {
    const number = await this.companyStore.addConnections(managerId, [this.id]);
    return number;
  }

  @action.bound
  async removeConnection(managerId: string) {
    await this.companyStore.removeConnections(managerId, [this.id]);
  }

  async remove() {
    await this.companyStore.removeUsersFromCompany([this.id]);
  }

  async resendInvitation() {
    await this.companyStore.resendInvitation(this.id);
  }
}
