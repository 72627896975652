//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CompanyUserDto } from '../../company/dto/CompanyUserDto';
import { IApiRequest } from '../../IApiRequest';

export class GetCompanyUserRequest extends IApiRequest<CompanyUserDto>
{
	public $type = 'GetCompanyUserRequest';
	constructor(public data: { userId: string, companyId: string }) { super(); }
}
