//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IApiRequest } from '../../IApiRequest';
import { ModuleContentId } from '../../content/model/ModuleContentId';

export class CompleteModuleRequest extends IApiRequest<void>
{
	public $type = 'CompleteModuleRequest';
	constructor(public data: { courseAssignmentId: string, moduleContentId: ModuleContentId }) { super(); }
}
