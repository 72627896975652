import { CompanyId } from "domain/company/CompanyId";
import { action, makeObservable, observable } from "mobx";
import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { GetCourseContentInfoRequest } from "../../../../infrastructure/types/api/coursecontents/GetCourseContentInfoRequest";
import { GetCourseContentRequest } from "../../../../infrastructure/types/api/coursecontents/GetCourseContentRequest";
import { GetCoursesContentInfoRequest } from "../../../../infrastructure/types/api/coursecontents/GetCoursesContentInfoRequest";
import { GetSelfAssignableCourseContentInfoRequest } from "../../../../infrastructure/types/api/coursecontents/GetSelfAssignableCourseContentInfoRequest";
import { ContentCourse, CourseDetailed, CourseInfo } from "../../models/Course";

export class CourseContentStore {
  @observable courses: ContentCourse[] = [];

  @observable selfAssignableCourses: ContentCourse[] = [];

  constructor(private httpClient: HttpClient) {
    makeObservable(this);
  }

  @action.bound
  public async loadCourseInfos(domainIds: string[]) {
    const response = await this.httpClient.send(new GetCoursesContentInfoRequest({ courseIds: domainIds }));

    const loadedCourses = response.map(dto => new CourseInfo(dto));

    this.courses = loadedCourses;
  }

  @action.bound
  public async loadSelfAssignableCourses(companyId: CompanyId) {
    const response = await this.httpClient.send(
      new GetSelfAssignableCourseContentInfoRequest({ companyId: companyId.value })
    );

    const loadedCourses = response.map(dto => new CourseInfo(dto));
    this.selfAssignableCourses = loadedCourses;
  }

  @action.bound
  private async selectCourse<T extends ContentCourse>(loadCourse: () => Promise<T>) {
    const loadedCourse = await loadCourse();

    const courseCurrentIndex = this.courses.findIndex(c => c.id === loadedCourse.id);
    if (courseCurrentIndex === -1) {
      this.courses.push(loadedCourse);
    } else {
      this.courses[courseCurrentIndex] = loadedCourse;
    }
    return loadedCourse;
  }

  async selectCourseDetailed(courseDomainId: string) {
    return this.selectCourse<CourseDetailed>(() => this.loadCourseDetailed(courseDomainId));
  }

  async selectCourseInfo(courseDomainId: string, companyId: CompanyId) {
    return this.selectCourse<CourseInfo>(() => this.loadCourseInfo(courseDomainId, companyId));
  }

  async getCourseInfos(domainIds: string[]) {
    this.loadCourseInfos(domainIds);

    return this.courses;
  }

  private async loadCourseDetailed(courseDomainId: string) {
    const response = await this.httpClient.send(new GetCourseContentRequest({ courseDomainId }));
    return new CourseDetailed(response);
  }

  private async loadCourseInfo(courseDomainId: string, companyId: CompanyId) {
    const response = await this.httpClient.send(
      new GetCourseContentInfoRequest({ courseDomainId, companyId: companyId.value })
    );
    return new CourseInfo(response);
  }
}
