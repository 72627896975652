import { CheckoutType } from "landing/application/pages/buyRedirect/LazyBuyRedirectPage";

interface CheckoutParams {
  courseSlug: string;
  packageSlug: string;
}

export class CheckoutLoginRequiredError extends Error {
  readonly code = 401;

  private params: CheckoutParams;

  constructor(stack: string, requestData: string, public type: CheckoutType) {
    super(stack);

    this.params = JSON.parse(requestData);
  }

  public getSlug() {
    if (this.type === "course") {
      return this.params.courseSlug;
    }
    return this.params.packageSlug;
  }
}
