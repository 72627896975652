//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { TestResultDto } from '../../test/dto/TestResultDto';
import { IApiRequest } from '../../IApiRequest';
import { CourseContentId } from '../../content/model/CourseContentId';

export class LockExamRequest extends IApiRequest<TestResultDto[]>
{
	public $type = 'LockExamRequest';
	constructor(public data: { courseAssignmentId: string, courseContentId: CourseContentId }) { super(); }
}
