import { CompanyId } from "domain/company/CompanyId";
import { GetCompanyUserRequest } from "infrastructure/types/api/users/GetCompanyUserRequest";
import { IsUserMemberOfMyNetworkRequest } from "infrastructure/types/api/users/IsUserMemberOfMyNetworkRequest";
import { action, makeObservable } from "mobx";
import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { GetCompanyManagersRequest } from "../../../../infrastructure/types/api/company/GetCompanyManagersRequest";
import { GetCompanyUsersRequest } from "../../../../infrastructure/types/api/company/GetCompanyUsersRequest";
import { GetUserPendingInvitationsRequest } from "../../../../infrastructure/types/api/invitation/GetUserPendingInvitationsRequest";
import { PaginationOptions, SearchPhrase } from "../../../../ui/loadMoreList/PaginatedList";
import { Company } from "../../models/Company";
import { CompanyUser } from "../../models/CompanyUser";

export class CompanyUserStore {
  constructor(private httpClient: HttpClient) {
    makeObservable(this);
  }

  @action.bound
  async getCompanyUsers(
    company: Company,
    searchPhrase: SearchPhrase,
    options: PaginationOptions,
    myNetworkOnly: boolean,
    activeOnly: boolean,
    invitedOnly: boolean,
    expiredOnly: boolean
  ) {
    const response = await this.httpClient.send(
      new GetCompanyUsersRequest({
        companyId: company.id.value,
        searchPhrase,
        ...options,
        myNetworkOnly,
        activeOnly,
        invitedOnly,
        expiredOnly,
      })
    );

    return {
      items: response.results.map(dto => new CompanyUser(company.companyStore, dto)),
      total: response.total,
    };
  }

  @action.bound
  async getCompanyManagers(company: Company, options: PaginationOptions) {
    const response = await this.httpClient.send(
      new GetCompanyManagersRequest({
        companyId: company.id.value,
        activeOnly: false,
        invitedOnly: false,
        expiredOnly: false,
        ...options,
      })
    );

    return {
      items: response.results.map(dto => new CompanyUser(company.companyStore, dto)),
      total: response.total,
    };
  }

  @action.bound
  async getCompanyUser(id: string, company: Company) {
    const dto = await this.httpClient.send(new GetCompanyUserRequest({ userId: id, companyId: company.id.value }));

    return new CompanyUser(company.companyStore, dto);
  }

  @action.bound
  async isUserMemberOfMyNetwork(userId: string, companyId: CompanyId) {
    return this.httpClient.send(new IsUserMemberOfMyNetworkRequest({ userId, companyId: companyId.value }));
  }

  @action.bound
  async getUserPendingInvitations() {
    return this.httpClient.send(new GetUserPendingInvitationsRequest());
  }
}
