import { action, makeObservable, observable, onBecomeObserved, runInAction } from "mobx";
import { CookiesConsentConfig } from "../../application/layout/cookiesConsent/CookiesConsentConfig";
import { FooterConfig } from "../../application/layout/footer/FooterConfig";
import { TibetPublicLinks } from "../../application/layout/publicLinks/TibetPublicLinks";
import { AppUser, LoggedInAppUser, AnonymousAppUser } from "../../application/models/appUser/AppUser";
import { HttpClient } from "../../infrastructure/api/HttpClient";
import { IGtmService } from "../../infrastructure/gtm/GtmService";
import { Unloaded } from "../../infrastructure/stores/models/Unloaded";
import { GetSiteConfigRequest } from "../../infrastructure/types/api/config/GetSiteConfigRequest";
import { TibetPageToJoinWithCodeRequest } from "../../infrastructure/types/api/invitation/TibetPageToJoinWithCodeRequest";
import { GetAppUserRequest } from "../../infrastructure/types/api/users/GetAppUserRequest";

type AppConfigState = "loaded" | "loading" | "unloaded" | "failedToLoad";
export class AppStore {
  @observable footerConfig: FooterConfig;

  @observable cookiesConsentConfig: CookiesConsentConfig;

  @observable state: AppConfigState = "unloaded";

  @observable tibetPublicLinks: TibetPublicLinks;

  @observable user: Unloaded | AppUser = new Unloaded();

  @observable isPaymentEnabled;

  @observable isMobileView: boolean = window.innerWidth <= 1100;

  constructor(public httpClient: HttpClient, public gtm: IGtmService) {
    makeObservable(this);
    this.footerConfig = new FooterConfig();
    this.cookiesConsentConfig = new CookiesConsentConfig(gtm);
    this.tibetPublicLinks = new TibetPublicLinks();
    this.isPaymentEnabled = false;
    window.addEventListener("resize", this.updateIsMobileView);
    onBecomeObserved(this, "footerConfig", async () => {
      if (this.state !== "unloaded") {
        return;
      }
      await runInAction(async () => {
        this.state = "loading";
        try {
          await this.loadConfig();
          this.state = "loaded";
        } catch (e) {
          this.state = "failedToLoad";
        }
      });
    });

    onBecomeObserved(this, "cookiesConsentConfig", async () => {
      if (this.state !== "unloaded") {
        return;
      }
      await runInAction(async () => {
        this.state = "loading";
        try {
          await this.loadConfig();
          this.state = "loaded";
        } catch (e) {
          this.state = "failedToLoad";
        }
      });
    });

    onBecomeObserved(this, "tibetPublicLinks", async () => {
      if (this.state !== "unloaded") {
        return;
      }
      await runInAction(async () => {
        this.state = "loading";
        try {
          await this.loadConfig();
          this.state = "loaded";
        } catch (e) {
          this.state = "failedToLoad";
        }
      });
    });

    onBecomeObserved(this, "isPaymentEnabled", async () => {
      if (this.state !== "unloaded") {
        return;
      }
      await runInAction(async () => {
        this.state = "loading";
        try {
          await this.loadConfig();
          this.state = "loaded";
        } catch (e) {
          this.state = "failedToLoad";
        }
      });
    });
  }

  @action.bound
  updateIsMobileView = () => {
    this.isMobileView = window.innerWidth <= 1100;
  };

  @action.bound
  async loadAppUser() {
    const getAppUserResponse = await this.httpClient.send(new GetAppUserRequest());
    const appUser = getAppUserResponse ? new LoggedInAppUser(getAppUserResponse) : new AnonymousAppUser();

    this.user = appUser;
    return appUser;
  }

  @action.bound
  async getJoinViaCodeUrl() {
    const url = await this.httpClient.send(new TibetPageToJoinWithCodeRequest());
    return url;
  }

  async loadConfig() {
    const response = await this.httpClient.send(new GetSiteConfigRequest({}));
    this.cookiesConsentConfig.setCookiesConsent(response.cookiesConsent);
    this.footerConfig.setFooterLinks(response.footerLinks);
    this.tibetPublicLinks.setLinks(response.tibetPublicLinks);
    this.isPaymentEnabled = response.isPaymentEnabled;
  }
}
