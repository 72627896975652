import React from "react";

export const useDelayedDisplay = (shouldBeDisplayed: boolean) => {
  const [isDisplayed, setIsDisplayed] = React.useState(shouldBeDisplayed);
  React.useEffect(() => {
    shouldBeDisplayed && setIsDisplayed(true);
  }, [shouldBeDisplayed]);

  const hide = () => !shouldBeDisplayed && setIsDisplayed(false);

  return [isDisplayed, hide] as const;
};
