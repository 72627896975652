/* eslint-disable react/react-in-jsx-scope */
/**
 * Source: https://github.com/0916dhkim/typed-react-router
 */

import { match as Match, useRouteMatch } from "react-router-dom";
import { isParams, Path, PathParams } from "./Paths";

// eslint-disable-next-line @typescript-eslint/ban-types
export const getParams = <P extends Path>(match: Match<{}> | null, path: P) => {
  if (!match || !isParams(path, match.params)) {
    throw new Error("Params not match");
  }

  return match.params;
};

/**
 * Type-safe version of `react-router-dom/useParams`.
 * @param path Path to match route.
 * @returns parameter object if route matches. `null` otherwise.
 */
export const useTypedParams = <P extends Path>(path: P): PathParams<P> => {
  // `exact`, `sensitive` and `strict` options are set to true
  // to ensure type safety.
  const match = useRouteMatch({
    path,
    exact: true,
    sensitive: true,
    strict: true,
  });

  return getParams(match, path);
};
