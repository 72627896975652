//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IApiRequest } from '../../IApiRequest';

export class GetContactPageContentRequest extends IApiRequest<void>
{
	public $type = 'GetContactPageContentRequest';
	constructor() { super(); }
}
