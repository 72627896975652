import { computed, makeObservable, observable } from "mobx";
import { CourseContentId } from "../../../infrastructure/types/content/model/CourseContentId";
import { CourseDto } from "../../../infrastructure/types/courses/dto/CourseDto";
import { CourseModeDto } from "../../../infrastructure/types/shared/dto/CourseModeDto";

export class CourseDomain {
  @observable id: string;

  @observable title: string;

  @observable mode: CourseModeDto;

  @observable timeEstimate: string;

  @observable price: number;

  @observable approvedBy: string;

  @observable isAssignable: boolean;

  @observable sanityCourseId?: CourseContentId;

  readonly _type = "courseDomain";

  @computed get isExam() {
    return this.mode === CourseModeDto.Exam;
  }

  constructor(dto: CourseDto) {
    makeObservable(this);
    this.id = dto.id;
    this.title = dto.title;
    this.mode = dto.mode;
    this.timeEstimate = dto.timeEstimate;
    this.price = dto.price;
    this.approvedBy = dto.approvedBy;
    this.isAssignable = dto.isAssignable;
    this.sanityCourseId = dto.sanityCourseId;
  }
}
