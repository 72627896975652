import { Path } from "../paths/Paths";
import { UserRoleDto } from "../types/shared/dto/UserRoleDto";

const UserRoles = [UserRoleDto.PaidUser, UserRoleDto.Member, UserRoleDto.Manager, UserRoleDto.Admin] as const;
const CompanyManagementRoles = [UserRoleDto.Manager, UserRoleDto.Admin] as const;
const CompanyAdminRoles = [UserRoleDto.Admin] as const;

export type PathAccessRights = "allowAnonymous" | readonly UserRoleDto[];

export const PathsToAccessRights: { [key in Path]: PathAccessRights } = {
  "/": "allowAnonymous",
  "/landingsside": "allowAnonymous",
  "/landingsside/kontakt": "allowAnonymous",
  "/landingsside/kurspakker": "allowAnonymous",
  "/landingsside/kurspakker/:packageSlug": "allowAnonymous",
  "/landingsside/kurs": "allowAnonymous",
  "/landingsside/kurs/:courseSlug": "allowAnonymous",
  "/invitation": "allowAnonymous",
  "/preview/task/:id": "allowAnonymous",
  "/dashboard": [],
  "/landingsside/kurs/:courseSlug/kjop": [],
  "/landingsside/kurspakker/:packageSlug/kjop": [],
  "/exam/:courseAssignmentId": UserRoles,
  "/course/:courseAssignmentId": UserRoles,
  "/course/:courseAssignmentId/browse": UserRoles,
  "/course/:courseAssignmentId/browse/test": UserRoles,
  "/course/:courseAssignmentId/browse/module/:moduleSlug": UserRoles,
  "/course/:courseAssignmentId/browse/module/:moduleSlug/test": UserRoles,
  "/course/:courseAssignmentId/browse/module/:moduleSlug/chapter/:chapterSlug": UserRoles,
  "/dossier/:courseSlug": [],
  "/merkompetanse/:courseSlug": [],
  "/manage": CompanyManagementRoles,
  "/manage/courses": CompanyManagementRoles,
  "/manage/courses/:courseSlug": CompanyManagementRoles,
  "/manage/courses/:courseSlug/activity": CompanyManagementRoles,
  "/manage/courses/:courseSlug/info": CompanyManagementRoles,
  "/manage/members": CompanyManagementRoles,
  "/manage/members/groups": CompanyManagementRoles,
  "/manage/users/:userId": CompanyManagementRoles,
  "/manage/groups/:groupId": CompanyManagementRoles,
  "/manage/company": CompanyAdminRoles,
  "/reports": CompanyManagementRoles,
  "/noMembership": [],
  "/myProfile": [],
  "/landingsside/betalingenErGjennomfort": [],
  "/landingsside/betalingenFeilet": [],
  "/informasjonskapsler": "allowAnonymous",
  "/bruks-og-kjopsvilkar": "allowAnonymous",
  "/personvern": "allowAnonymous",
  "/ikke-tilgang": "allowAnonymous",
};
