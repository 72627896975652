import { GroupSearchStore } from "domain/userManagement/stores/searchStore/GroupSearchStore";
import { UserSearchStore } from "domain/userManagement/stores/searchStore/UserSearchStore";
import { AppStore } from "../domain/app/AppStore";
import { CourseStatsStore } from "../domain/course/stores/courseStatsStore/CourseStatsStore";
import { CourseDomainStore } from "../domain/course/stores/courseStore/CourseDomainStore";
import { CourseAssignmentStore } from "../domain/courseAssigning/stores/courseAssignmentStore/CourseAssignmentStore";
import { InvitationStore } from "../domain/courseAssigning/stores/invitationStore/InvitationStore";
import { ManagerCourseAssignmentStore } from "../domain/courseAssigning/stores/managerCourseAssignmentStore/ManagerCourseAssignmentStore";
import { CourseContentStore } from "../domain/courseContent/stores/courseContentStore/CourseContentStore";
import { ProgressStore } from "../domain/progress/stores/ProgressStore";
import { ReportStore } from "../domain/report/ReportStore";
import { TestStore } from "../domain/test/stores/testStore/TestStore";
import { CompanyStore } from "../domain/userManagement/stores/companyStore/CompanyStore";
import { CompanyUserStore } from "../domain/userManagement/stores/companyStore/CompanyUserStore";
import { GroupStore } from "../domain/userManagement/stores/groupStore/GroupStore";
import { UserAndGroupSearchStore } from "../domain/userManagement/stores/searchStore/UserAndGroupSearchStore";
import { HttpClient } from "../infrastructure/api/HttpClient";

export class AppViewModel {
  constructor(
    public courseDomainStore: CourseDomainStore,
    public courseContentStore: CourseContentStore,
    public testStore: TestStore,
    public appStore: AppStore,
    public userAndGroupSearchStore: UserAndGroupSearchStore,
    public groupSearchStore: GroupSearchStore,
    public userSearchStore: UserSearchStore,
    public companyStore: CompanyStore,
    public companyUserStore: CompanyUserStore,
    public courseAssignmentStore: CourseAssignmentStore,
    public managerCourseAssignmentsStore: ManagerCourseAssignmentStore,
    public courseStatsStore: CourseStatsStore,
    public progressStore: ProgressStore,
    public invitationStore: InvitationStore,
    public groupStore: GroupStore,
    public reportStore: ReportStore,
    public httpClient: HttpClient
  ) {}
}
