import { CompanyId } from "domain/company/CompanyId";
import { makeObservable, observable } from "mobx";
import { CompanyDto } from "../../../infrastructure/types/company/dto/CompanyDto";
import { CompanyStore } from "../stores/companyStore/CompanyStore";

export class Company {
  @observable id: CompanyId;

  @observable name: string;

  @observable allowSelfAssign: boolean;

  @observable showCourses: boolean;

  @observable canEnableDossier: boolean;

  @observable dossierEnabled: boolean;

  @observable canEnableMerKompetanse: boolean;

  @observable merKompetanseEnabled: boolean;

  readonly _type = "company";

  constructor(dto: CompanyDto, public companyStore: CompanyStore) {
    makeObservable(this);
    this.id = new CompanyId(dto.id);
    this.name = dto.name;
    this.allowSelfAssign = dto.allowSelfAssign;
    this.showCourses = dto.showCourses;
    this.canEnableDossier = dto.canEnableDossierIntegration;
    this.dossierEnabled = dto.dossierIntegration;
    this.canEnableMerKompetanse = dto.canEnableMerKompetanse;
    this.merKompetanseEnabled = dto.merKompetanseIntegration;
  }

  addMember(userEmail: string) {
    return this.companyStore.addMemberToCompany(userEmail, this.id);
  }

  async deleteMembers(ids: string[]) {
    await this.companyStore.removeUsersFromCompany(ids);
  }
}
