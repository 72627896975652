import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppViewModel } from "application/AppViewModel";
import "application/i18n";
import { LazyInvitation } from "application/pages/invitation/LazyInvitation";
import reportWebVitals from "application/reportWebVitals";
import axios from "axios";
import "focus-visible";
import "intersection-observer";
import { observer } from "mobx-react";
import React, { Suspense } from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "svgxuse";
import { CookiesConsent } from "./application/layout/cookiesConsent/CookiesConsent";
import { initAxiosGlobalInterceptors } from "./infrastructure/api/AxiosClient";
import { HttpClient } from "./infrastructure/api/HttpClient";
import { TypedRoute } from "./infrastructure/components/TypedRoute";
import { ErrorView } from "./infrastructure/errors/ErrorView";
import stores from "./infrastructure/stores/stores";
import { Loader } from "./ui/loader/Loader";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;
const { appInsightsInstrumentationKey } = window;

if (appInsightsInstrumentationKey) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appInsightsInstrumentationKey,
      /* ...Other Configuration Options... */
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}

initAxiosGlobalInterceptors();
const axiosCustom = axios.create();

const http = new HttpClient(axiosCustom);

const {
  courseDomainStore,
  courseContentStore,
  testStore,
  appStore,
  userAndGroupSearchStore,
  groupSearchStore,
  userSearchStore,
  companyStore,
  companyUserStore,
  courseAssignmentStore,
  managerCourseAssignmentsStore,
  courseStatsStore,
  progressStore,
  invitationStore,
  groupStore,
  reportStore,
  landingStore,
} = stores(http);

const appViewModel = new AppViewModel(
  courseDomainStore,
  courseContentStore,
  testStore,
  appStore,
  userAndGroupSearchStore,
  groupSearchStore,
  userSearchStore,
  companyStore,
  companyUserStore,
  courseAssignmentStore,
  managerCourseAssignmentsStore,
  courseStatsStore,
  progressStore,
  invitationStore,
  groupStore,
  reportStore,
  http
);

const LazyLandingApp = React.memo(() => {
  const Lazy = React.lazy(async () => {
    const { App } = await import(/* webpackChunkName: "LandingApp" */ "./landing/application/App");

    return {
      default: () => <App appStore={appStore} landingStore={landingStore} />,
    };
  });

  return <Lazy />;
});

const LazyMainApp = React.memo(() => {
  const Lazy = React.lazy(async () => {
    const { App } = await import(/* webpackChunkName: "MainApp" */ "./application/App");

    return {
      default: () => <App model={appViewModel} />,
    };
  });

  return <Lazy />;
});

const Application = observer(() => {
  return (
    <Router>
      <Switch>
        <TypedRoute exact path="/invitation" render={() => <LazyInvitation invitationStore={invitationStore} />} />
        <TypedRoute
          path="/"
          render={() => (
            <>
              <CookiesConsent model={appStore.cookiesConsentConfig} />
              <Switch>
                <TypedRoute path="/landingsside" render={() => <LazyLandingApp />} />
                <TypedRoute path="/" render={() => <LazyMainApp />} />
              </Switch>
            </>
          )}
        />
      </Switch>
    </Router>
  );
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary fallbackRender={({ error }) => <ErrorView error={error} />}>
      <HelmetProvider>
        <Suspense fallback={<Loader />}>
          <Application />
        </Suspense>
      </HelmetProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
