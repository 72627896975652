//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { TestResultDto } from '../../test/dto/TestResultDto';
import { IApiRequest } from '../../IApiRequest';
import { UserTaskAnswerDto } from '../../test/dto/UserTaskAnswerDto';
import { CourseContentId } from '../../content/model/CourseContentId';

export class SubmitExamTestRequest extends IApiRequest<TestResultDto>
{
	public $type = 'SubmitExamTestRequest';
	constructor(public data: { userTaskAnswers: UserTaskAnswerDto[], courseContentId: CourseContentId, courseAssignmentId: string, useDossier?: boolean }) { super(); }
}
