import { CompanyId } from "domain/company/CompanyId";
import { GetCourseAssignmentStatsRequest } from "infrastructure/types/api/courseassignments/GetCourseAssignmentStatsRequest";
import { makeObservable, observable } from "mobx";
import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { CourseAssignmentStats } from "../../models/CourseStats";

export class CourseStatsStore {
  @observable courseStats: CourseAssignmentStats[] = [];

  constructor(private httpClient: HttpClient) {
    makeObservable(this);
  }

  public async loadCourseAssignmentStats(companyId: CompanyId, courseIds: string[]) {
    const result = await this.httpClient.send(
      new GetCourseAssignmentStatsRequest({ companyId: companyId.value, courseIds })
    );
    const loadedCourseStats = result.map(courseStatsDto => new CourseAssignmentStats(courseStatsDto));

    this.courseStats = loadedCourseStats;
  }
}
