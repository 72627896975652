import { CompanyId } from "domain/company/CompanyId";
import { computed, makeObservable, observable } from "mobx";
import { UserDto } from "../../../infrastructure/types/shared/dto/UserDto";
import { UserRoleDto } from "../../../infrastructure/types/shared/dto/UserRoleDto";
import { UserTypeDto } from "../../../infrastructure/types/shared/dto/UserTypeDto";
import { getUserDisplayName, getUserInitials } from "../../../utils/userUtils";

export class AnonymousAppUser {
  readonly _type = "anonymous";
}

export class LoggedInAppUser {
  @observable id: string;

  @observable firstName?: string;

  @observable lastName?: string;

  @observable role?: UserRoleDto;

  @observable defaultCompanyId: CompanyId;

  @observable defaultCompanyName: string;

  @observable email: string;

  @observable phone?: string;

  @observable hasAnyActiveMemberships: boolean;

  @observable hasAnyMemberships: boolean;

  @observable allowSelfAssign: boolean;

  @observable showCourses: boolean;

  @observable dossierEnabled: boolean;

  @observable merKompetanseEnabled: boolean;

  @observable userType: UserTypeDto;

  readonly _type = "loggedIn";

  constructor(userDto: UserDto) {
    makeObservable(this);
    this.id = userDto.id;
    this.firstName = userDto.firstName;
    this.lastName = userDto.lastName;
    this.role = userDto.role;
    this.defaultCompanyId = new CompanyId(userDto.defaultCompanyId);
    this.defaultCompanyName = userDto.defaultCompanyName;
    this.email = userDto.email;
    this.phone = userDto.phone;
    this.hasAnyActiveMemberships = userDto.hasAnyActiveMemberships;
    this.hasAnyMemberships = userDto.hasAnyMemberships;
    this.allowSelfAssign = userDto.allowSelfAssign ?? false;
    this.showCourses = userDto.showCourses ?? false;
    this.dossierEnabled = userDto.dossierEnabled ?? false;
    this.merKompetanseEnabled = userDto.merKompetanseEnabled ?? false;
    this.userType = userDto.userType;
  }

  @computed get initials() {
    return getUserInitials(this.email, this.firstName, this.lastName);
  }

  @computed get isManager() {
    return this.role === UserRoleDto.Manager;
  }

  @computed get isAdmin() {
    return this.role === UserRoleDto.Admin;
  }

  @computed get displayName() {
    return getUserDisplayName(this.email, this.firstName, this.lastName);
  }
}

export type AppUser = AnonymousAppUser | LoggedInAppUser;
