import { AppUser } from "../../application/models/appUser/AppUser";
import { Path } from "../paths/Paths";
import { PathsToAccessRights } from "./PathsToAccessRights";

export const hasUserAccess = <P extends Path>(user: AppUser, path: P) => {
  const rolesWithAccess = PathsToAccessRights[path];

  if (rolesWithAccess === "allowAnonymous") {
    return true;
  }

  if (user._type === "anonymous") {
    return false;
  }

  if (rolesWithAccess.length === 0) {
    return true;
  }

  return user.role !== null && rolesWithAccess.some(role => role === user.role);
};
