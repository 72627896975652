import React from "react";
import { LinkPropsBase, getLinkClassNames } from "./Link";

interface ExternalLinkProps
  extends LinkPropsBase,
    Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, "aria-describedby">,
    Pick<React.AnchorHTMLAttributes<HTMLAnchorElement>, "target"> {
  href: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const ExternalLink: React.FC<ExternalLinkProps> = props => {
  const {
    href,
    children,
    type = "primary",
    buttonType = "solid",
    buttonVariant = "primary",
    size = "inherit",
    buttonSize = "medium",
    target,
    className,
    tabIndex,
    onClick,
  } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const ariaDescribedBy = props["aria-describedby"];

  const targetProps = target === "_blank" ? { target, rel: "noreferrer" } : { target };

  return (
    <a
      aria-describedby={ariaDescribedBy}
      href={href}
      tabIndex={tabIndex}
      className={getLinkClassNames(type, buttonType, buttonVariant, size, buttonSize, className)}
      onClick={onClick}
      {...targetProps}
    >
      {children}
    </a>
  );
};
