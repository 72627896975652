//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GroupStatsDto } from '../../group/dto/GroupStatsDto';
import { PagedResultDto } from '../../shared/dto/PagedResultDto';
import { IApiRequest } from '../../IApiRequest';

export class GetGroupStatsRequest extends IApiRequest<PagedResultDto<GroupStatsDto>>
{
	public $type = 'GetGroupStatsRequest';
	constructor(public data: { companyId: string, skip: number, take: number, myNetworkOnly: boolean, searchPhrase?: string }) { super(); }
}
