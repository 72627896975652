import classNames from "classnames";
import Cookies from "js-cookie";
import { observer } from "mobx-react";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";
import { useDelayedDisplay } from "../../../infrastructure/hooks/useDelayedDisplay";
import { Button } from "../../../ui/button/Button";
import { Modal } from "../../../ui/modal/Modal";
import {
  AcceptedCookiesConsentValue,
  AnalysisCookieName,
  CookiesConsentConfig,
  NecessaryCookieName,
} from "./CookiesConsentConfig";

interface CookiesConsentProps {
  model: CookiesConsentConfig;
  onAccept?: () => void;
}

export const CookiesConsent: React.FC<CookiesConsentProps> = observer(({ model, onAccept }) => {
  const { cookiesConsent, toggleCookie, acceptSpecificCookies, acceptAllCookies } = model;
  const { t } = useTranslation();
  const [cookiesConsentState, setCookiesConsentState] = React.useState(Cookies.get(NecessaryCookieName));
  const shouldCookiesConsentBeOpened = cookiesConsentState !== AcceptedCookiesConsentValue;
  const [isDisplayed, hide] = useDelayedDisplay(shouldCookiesConsentBeOpened);
  const [showModal, setShowModal] = useState<boolean>(false);
  const cookiesConsentStyles = useSpring({
    from: { transform: `translateY(100%)` },
    transform: shouldCookiesConsentBeOpened ? `translateY(0%)` : `translateY(100%)`,
    onResolve: hide,
    config: config.stiff,
  });

  const cookies = [
    {
      id: "0",
      name: NecessaryCookieName,
      title: "Nødvendige informasjonskapsler",
      mandatory: true,
      description:
        "Disse informasjonskapslene støtter opp under kjernefunksjonalitet knyttet til sikkerhet. Vi har vurdert disse som nødvendige, og de lagres derfor uten samtykke.",
    },
    {
      id: "1",
      name: AnalysisCookieName,
      title: "Analyse og markedsføring",
      mandatory: false,
      description:
        "Vi bruker informasjonskapsler til å analysere bruk av nettsiden for å skape en bedre brukeropplevelse for deg og tilby relevant markedsføring gjennom andre nettsteder, inkludert sosiale medier.",
    },
  ];
  const acceptCookies = () => {
    acceptAllCookies();
    onAccept && onAccept();
    setCookiesConsentState(AcceptedCookiesConsentValue);
  };

  const acceptSpecificCookiesLocalFunction = () => {
    acceptSpecificCookies();
    onAccept && onAccept();
    setCookiesConsentState(AcceptedCookiesConsentValue);
    setShowModal(false);
  };
  return (
    <>
      {isDisplayed ? (
        <animated.div
          className="CookiesConsent"
          role="alertdialog"
          aria-labelledby="CookiesConsent__title"
          aria-describedby="CookiesConsent__message"
          style={cookiesConsentStyles}
        >
          <p id="CookiesConsent__title" className="visuallyHidden">
            {t("cookieNotice")}
          </p>
          <div className="CookiesConsent__inner">
            <p id="CookiesConsent__message" className="CookiesConsent__message">
              {cookiesConsent.consentText}
            </p>
            <div className="CookiesConsent__buttons">
              <Button onClick={() => setShowModal(true)} variant="secondary" size="large">
                Endre cookies
              </Button>
              <Button onClick={acceptCookies} variant="primary" size="large">
                {t("acceptAllCookies")}
              </Button>
            </div>
          </div>
        </animated.div>
      ) : null}

      {showModal && (
        <div className="ConsentModal">
          <Modal isOpen={showModal} onClose={() => setShowModal(false)} className="ConsentModal">
            <h1 className="ConsentModal__header">Samtykkeinnstillinger</h1>
            <p> {cookiesConsent.consentText}</p>
            <a target="_blank" rel="noreferrer" className="ConsentModal__link" href="/informasjonskapsler">
              Gyldendals bruk av informasjonskapsler
            </a>
            <div>
              {cookies.map(cookie => (
                <div key={cookie.id}>
                  <div className="ConsentModal__cookie-button-wrapper">
                    <div
                      className={classNames(
                        "ConsentModal__title-and-toggle-wrapper",
                        cookie.mandatory && "DisabledInput"
                      )}
                    >
                      <h3>{cookie.title}</h3>
                      <input
                        type="checkbox"
                        id={cookie.id}
                        checked={model.selectedCookies[cookie.name]}
                        onChange={() => toggleCookie(cookie.name)}
                        disabled={cookie.mandatory}
                      />
                      <label htmlFor={cookie.id}>Toggle</label>
                    </div>
                  </div>
                  <p className={cookie.description}>{cookie.description}</p>
                </div>
              ))}
            </div>
            <div className="AcceptButton__wrapper">
              <Button onClick={acceptSpecificCookiesLocalFunction} variant="primary" size="large">
                Aksepter
              </Button>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
});
