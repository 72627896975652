import { GroupSearchStore } from "domain/userManagement/stores/searchStore/GroupSearchStore";
import { UserSearchStore } from "domain/userManagement/stores/searchStore/UserSearchStore";
import { configure } from "mobx";
import { AppStore } from "../../domain/app/AppStore";
import { CourseStatsStore } from "../../domain/course/stores/courseStatsStore/CourseStatsStore";
import { CourseDomainStore } from "../../domain/course/stores/courseStore/CourseDomainStore";
import { CourseAssignmentStore } from "../../domain/courseAssigning/stores/courseAssignmentStore/CourseAssignmentStore";
import { InvitationStore } from "../../domain/courseAssigning/stores/invitationStore/InvitationStore";
import { ManagerCourseAssignmentStore } from "../../domain/courseAssigning/stores/managerCourseAssignmentStore/ManagerCourseAssignmentStore";
import { CourseContentStore } from "../../domain/courseContent/stores/courseContentStore/CourseContentStore";
import { ProgressStore } from "../../domain/progress/stores/ProgressStore";
import { ReportStore } from "../../domain/report/ReportStore";
import { TestStore } from "../../domain/test/stores/testStore/TestStore";
import { CompanyStore } from "../../domain/userManagement/stores/companyStore/CompanyStore";
import { CompanyUserStore } from "../../domain/userManagement/stores/companyStore/CompanyUserStore";
import { GroupStore } from "../../domain/userManagement/stores/groupStore/GroupStore";
import { UserAndGroupSearchStore } from "../../domain/userManagement/stores/searchStore/UserAndGroupSearchStore";
import { LandingStore } from "../../landing/application/LandingStore";
import { HttpClient } from "../api/HttpClient";
import { GtmService } from "../gtm/GtmService";

//  THIS IS HERE FOR IE11 SUPPORT PURPOSES. WE HAVE TO ENABLE PROXIES BEFORE INITIALIZING FIRST STORES
configure({
  useProxies: "never",
});

export default (http: HttpClient) => {
  return {
    appStore: new AppStore(http, new GtmService()),
    courseContentStore: new CourseContentStore(http),
    courseDomainStore: new CourseDomainStore(http),
    testStore: new TestStore(http),
    companyStore: new CompanyStore(http),
    companyUserStore: new CompanyUserStore(http),
    courseAssignmentStore: new CourseAssignmentStore(http),
    managerCourseAssignmentsStore: new ManagerCourseAssignmentStore(http),
    courseStatsStore: new CourseStatsStore(http),
    progressStore: new ProgressStore(http),
    invitationStore: new InvitationStore(http),
    userAndGroupSearchStore: new UserAndGroupSearchStore(http),
    groupSearchStore: new GroupSearchStore(http),
    userSearchStore: new UserSearchStore(http),
    groupStore: new GroupStore(http),
    reportStore: new ReportStore(http),
    landingStore: new LandingStore(http),
  };
};
