import { CompanyId } from "domain/company/CompanyId";
import { GroupLeader } from "domain/userManagement/models/GroupLeader";
import { GroupMember } from "domain/userManagement/models/GroupUser";
import { AddLeadersToGroupRequest } from "infrastructure/types/api/group/AddLeadersToGroupRequest";
import { GetGroupLeadersRequest } from "infrastructure/types/api/group/GetGroupLeadersRequest";
import { GetGroupStatsRequest } from "infrastructure/types/api/group/GetGroupStatsRequest";
import { GetSingleGroupStatsRequest } from "infrastructure/types/api/group/GetSingleGroupStatsRequest";
import { RemoveLeadersFromGroupRequest } from "infrastructure/types/api/group/RemoveLeadersFromGroupRequest";
import { RenameGroupRequest } from "infrastructure/types/api/group/RenameGroupRequest";
import { action, makeObservable } from "mobx";
import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { AddGroupRequest } from "../../../../infrastructure/types/api/group/AddGroupRequest";
import { AddMembersToGroupRequest } from "../../../../infrastructure/types/api/group/AddMembersToGroupRequest";
import { GetGroupCourseAssignmentsWithStatsRequest } from "../../../../infrastructure/types/api/group/GetGroupCourseAssignmentsWithStatsRequest";
import { GetGroupUsersRequest } from "../../../../infrastructure/types/api/group/GetGroupUsersRequest";
import { GetGroupsLedByUserRequest } from "../../../../infrastructure/types/api/group/GetGroupsLedByUserRequest";
import { RemoveGroupRequest } from "../../../../infrastructure/types/api/group/RemoveGroupRequest";
import { RemoveMembersFromGroupRequest } from "../../../../infrastructure/types/api/group/RemoveMembersFromGroupRequest";
import { PaginationOptions, SearchPhrase } from "../../../../ui/loadMoreList/PaginatedList";
import { Group } from "../../models/Group";
import { GroupCourseAssignmentWithStats } from "../../models/GroupCourseAssignmentWithStats";

export class GroupStore {
  constructor(private httpClient: HttpClient) {
    makeObservable(this);
  }

  @action.bound
  public addGroup(companyId: CompanyId, groupName: string, userIds: string[]) {
    return this.httpClient.send(new AddGroupRequest({ companyId: companyId.value, groupName, userIds }));
  }

  @action.bound
  public addMembersToGroup(groupId: string, companyId: CompanyId, userIds: string[]) {
    return this.httpClient.send(new AddMembersToGroupRequest({ groupId, companyId: companyId.value, userIds }));
  }

  @action.bound
  public async removeMembersFromGroup(groupId: string, companyId: CompanyId, userIds: string[]) {
    const result = await this.httpClient.send(
      new RemoveMembersFromGroupRequest({ groupId, companyId: companyId.value, userIds })
    );
    return result;
  }

  @action.bound
  public async getGroups(
    companyId: CompanyId,
    options: PaginationOptions,
    searchPhrase: SearchPhrase,
    myNetworkOnly: boolean
  ) {
    const response = await this.httpClient.send(
      new GetGroupStatsRequest({ companyId: companyId.value, ...options, myNetworkOnly, searchPhrase })
    );

    return {
      items: response.results.map(dto => new Group(dto, companyId, this)),
      total: response.total,
    };
  }

  @action.bound
  public async getGroup(groupId: string, companyId: CompanyId) {
    const dto = await this.loadGroup(groupId, companyId);
    const group = new Group(dto, companyId, this);

    if (group?._type === "group") {
      return group;
    }

    throw new Error("Group not found");
  }

  @action.bound
  public async getGroupCourseAssignmentsWithStats(groupId: string) {
    const dtos = await this.httpClient.send(new GetGroupCourseAssignmentsWithStatsRequest({ groupId }));

    const groupCourseAssignmentsWithStats = dtos.map(gca => new GroupCourseAssignmentWithStats(gca));
    return groupCourseAssignmentsWithStats;
  }

  @action.bound
  public async loadGroup(groupId: string, companyId: CompanyId) {
    const dto = await this.httpClient.send(new GetSingleGroupStatsRequest({ groupId, companyId: companyId.value }));
    return dto;
  }

  @action.bound
  public async removeGroup(groupId: string, companyId: CompanyId) {
    await this.httpClient.send(new RemoveGroupRequest({ groupId, companyId: companyId.value }));
  }

  @action.bound
  public async renameGroup(groupId: string, groupName: string, companyId: CompanyId) {
    await this.httpClient.send(new RenameGroupRequest({ groupId, groupName, companyId: companyId.value }));
  }

  @action.bound
  public addLeadersToGroup(groupId: string, companyId: CompanyId, userIds: string[]) {
    return this.httpClient.send(new AddLeadersToGroupRequest({ groupId, companyId: companyId.value, userIds }));
  }

  @action.bound
  public async removeLeadersFromGroup(groupId: string, companyId: CompanyId, userIds: string[]) {
    await this.httpClient.send(new RemoveLeadersFromGroupRequest({ groupId, companyId: companyId.value, userIds }));
  }

  @action.bound
  public async getGroupsLedByUser(userId: string, companyId: string) {
    const groups = await this.httpClient.send(new GetGroupsLedByUserRequest({ userId, companyId }));

    return groups;
  }

  public async getGroupUsers(companyId: CompanyId, groupId: string, options: PaginationOptions) {
    const { results, total } = await this.httpClient.send(
      new GetGroupUsersRequest({ companyId: companyId.value, groupId, ...options })
    );

    const items = results.map(dto => new GroupMember(dto));

    return { items, total };
  }

  public async getGroupLeaders(companyId: CompanyId, groupId: string, options: PaginationOptions) {
    const { results, total } = await this.httpClient.send(
      new GetGroupLeadersRequest({ companyId: companyId.value, groupId, ...options })
    );

    const items = results.map(dto => new GroupLeader(dto));

    return {
      items,
      total,
    };
  }
}
