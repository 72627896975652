//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CoursePackageDto } from '../../coursepackages/dto/CoursePackageDto';
import { IApiRequest } from '../../IApiRequest';

export class GetPackageRequest extends IApiRequest<CoursePackageDto>
{
	public $type = 'GetPackageRequest';
	constructor(public data: { slug: string, lang?: string }) { super(); }
}
