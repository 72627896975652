import { CompanyId } from "domain/company/CompanyId";
import { action, makeObservable, observable } from "mobx";
import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { AddSelfAssignedCourseAssignmentRequest } from "../../../../infrastructure/types/api/courseassignments/AddSelfAssignedCourseAssignmentRequest";
import { GetCourseAssignmentRequest } from "../../../../infrastructure/types/api/courseassignments/GetCourseAssignmentRequest";
import { GetUserCourseAssignmentsRequest } from "../../../../infrastructure/types/api/courseassignments/GetUserCourseAssignmentsRequest";
import { StartCourseAssignmentRequest } from "../../../../infrastructure/types/api/courseassignments/StartCourseAssignmentRequest";
import { CourseAssignment } from "../../models/CourseAssignment";

export class CourseAssignmentStore {
  @observable courseAssignments: CourseAssignment[] = [];

  constructor(private httpClient: HttpClient) {
    makeObservable(this);
  }

  @action.bound
  public async getUserAssignments(userId: string, companyId?: CompanyId) {
    const getUserCourseAssignmentsResponse = await this.httpClient.send(
      new GetUserCourseAssignmentsRequest({ userId, companyId: companyId?.value })
    );
    this.courseAssignments = getUserCourseAssignmentsResponse.map(dto => new CourseAssignment(this, dto));

    return this.courseAssignments;
  }

  @action.bound
  public async getCourseAssignment(id: string) {
    const result = await this.loadCourseAssignment(id);
    return new CourseAssignment(this, result);
  }

  @action.bound
  public async loadCourseAssignment(id: string) {
    const result = await this.httpClient.send(new GetCourseAssignmentRequest({ id }));
    return result;
  }

  @action.bound
  public async startCourseAssignment(id: string) {
    await this.httpClient.send(new StartCourseAssignmentRequest({ id }));
  }

  @action.bound
  public async addSelfAssignedCourseAssignment(courseId: string, companyId: CompanyId) {
    const activeFrom = new Date(Date.now()).toJSON();
    const activeTo = new Date(Date.now().valueOf() + 5259600000).toJSON();

    const updatedCourseassignments = await this.httpClient.send(
      new AddSelfAssignedCourseAssignmentRequest({
        courseId,
        companyId: companyId.value,
        activeFrom,
        activeTo,
      })
    );
    return updatedCourseassignments;
  }
}
