import { makeObservable, observable } from "mobx";
import { ChapterContentId } from "../../../infrastructure/types/content/model/ChapterContentId";
import { ChapterDto } from "../../../infrastructure/types/coursecontents/dto/ChapterDto";
import { PortableTextDto } from "../../../infrastructure/types/shared/dto/PortableTextDto";
import { ModuleDetailed } from "./Module";

export class Chapter {
  @observable id: ChapterContentId;

  @observable slug: string;

  @observable title: string;

  @observable content: PortableTextDto;

  constructor(public module: ModuleDetailed, dto: ChapterDto) {
    makeObservable(this);

    this.id = dto.id;
    this.slug = dto.slug;
    this.title = dto.title;
    this.content = dto.content;
  }
}
