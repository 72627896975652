//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ManagerEmployeeConnectionCreatedDto } from '../../users/dto/ManagerEmployeeConnectionCreatedDto';
import { IApiRequest } from '../../IApiRequest';

export class AddConnectionsRequest extends IApiRequest<ManagerEmployeeConnectionCreatedDto>
{
	public $type = 'AddConnectionsRequest';
	constructor(public data: { managerId: string, userIds: string[], companyId: string }) { super(); }
}
