//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GroupItemDto } from '../../users/dto/GroupItemDto';
import { PagedResultDto } from '../../shared/dto/PagedResultDto';
import { IApiRequest } from '../../IApiRequest';

export class SearchGroupsRequest extends IApiRequest<PagedResultDto<GroupItemDto>>
{
	public $type = 'SearchGroupsRequest';
	constructor(public data: { companyId: string, skip: number, take: number, searchPhrase?: string }) { super(); }
}
