//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CoursesListDto } from '../../courseslist/dto/CoursesListDto';
import { IApiRequest } from '../../IApiRequest';

export class GetCoursesListRequest extends IApiRequest<CoursesListDto>
{
	public $type = 'GetCoursesListRequest';
	constructor(public data: { lang?: string }) { super(); }
}
