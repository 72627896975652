import { CompanyId } from "domain/company/CompanyId";
import { ResendInvitationRequest } from "infrastructure/types/api/invitation/ResendInvitationRequest";
import { action, makeObservable, observable } from "mobx";
import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { Unloaded } from "../../../../infrastructure/stores/models/Unloaded";
import { AssignManagerRoleRequest } from "../../../../infrastructure/types/api/company/AssignManagerRoleRequest";
import { GetCompanyLicenseUsageRequest } from "../../../../infrastructure/types/api/company/GetCompanyLicenseUsageRequest";
import { GetCompanyRequest } from "../../../../infrastructure/types/api/company/GetCompanyRequest";
import { RevokeManagerRoleRequest } from "../../../../infrastructure/types/api/company/RevokeManagerRoleRequest";
import { AddConnectionsRequest } from "../../../../infrastructure/types/api/users/AddConnectionsRequest";
import { AddMemberRequest } from "../../../../infrastructure/types/api/users/AddMemberRequest";
import { RemoveConnectionsRequest } from "../../../../infrastructure/types/api/users/RemoveConnectionsRequest";
import { RemoveUsersFromCompanyRequest } from "../../../../infrastructure/types/api/users/RemoveUsersFromCompanyRequest";
import { Company } from "../../models/Company";

export class CompanyStore {
  @observable companies: Company[] = [];

  @observable currentCompany: Company | Unloaded = new Unloaded();

  constructor(private httpClient: HttpClient) {
    makeObservable(this);
  }

  @action.bound
  async selectCompany(id: CompanyId) {
    const foundCompany = this.companies.find(c => c.id.value === id.value);
    if (foundCompany) {
      this.currentCompany = foundCompany;
      return foundCompany;
    }

    const loadedCompany = await this.getCompany(id);
    if (loadedCompany._type === "company") {
      this.companies.push(loadedCompany);
    }
    this.currentCompany = loadedCompany;
    return loadedCompany;
  }

  async assignManagerRole(userId: string) {
    if (this.currentCompany._type === "company") {
      await this.httpClient.send(new AssignManagerRoleRequest({ userId, companyId: this.currentCompany.id.value }));
    }
  }

  async revokeManagerRole(userId: string) {
    if (this.currentCompany._type === "company") {
      await this.httpClient.send(new RevokeManagerRoleRequest({ userId, companyId: this.currentCompany.id.value }));
    }
  }

  addMemberToCompany(userEmail: string, companyId: CompanyId) {
    return this.httpClient.send(new AddMemberRequest({ userEmail, companyId: companyId.value }));
  }

  async removeUsersFromCompany(userIds: string[]) {
    if (this.currentCompany._type === "company") {
      await this.httpClient.send(
        new RemoveUsersFromCompanyRequest({ userIds, companyId: this.currentCompany.id.value })
      );
    }
  }

  async resendInvitation(userId: string) {
    if (this.currentCompany._type === "company") {
      await this.httpClient.send(new ResendInvitationRequest({ userId, companyId: this.currentCompany.id.value }));
    }
  }

  async getLicenseUsage() {
    if (this.currentCompany._type !== "company") {
      return;
    }
    const licenseUsage = await this.httpClient.send(
      new GetCompanyLicenseUsageRequest({ companyId: this.currentCompany.id.value })
    );
    return licenseUsage;
  }

  async addConnections(managerId: string, userIds: string[]) {
    if (this.currentCompany._type !== "company") {
      return 0;
    }

    const numberAdded = await this.httpClient.send(
      new AddConnectionsRequest({
        managerId,
        userIds,
        companyId: this.currentCompany.id.value,
      })
    );

    return numberAdded;
  }

  async removeConnections(managerId: string, userIds: string[]) {
    if (this.currentCompany._type !== "company") {
      return;
    }

    await this.httpClient.send(
      new RemoveConnectionsRequest({
        managerId,
        userIds,
        companyId: this.currentCompany.id.value,
      })
    );
  }

  private async getCompany(id: CompanyId) {
    const companyDto = await this.loadCompany(id);
    return new Company(companyDto, this);
  }

  private async loadCompany(id: CompanyId) {
    const companyDto = await this.httpClient.send(new GetCompanyRequest({ companyId: id.value }));
    return companyDto;
  }
}
