import { CompanyId } from "domain/company/CompanyId";
import { computed, makeObservable, observable } from "mobx";
import { AssignmentStageDto } from "../../../infrastructure/types/courseassignments/dto/AssignmentStageDto";
import { IndividualCourseAssignmentDto } from "../../../infrastructure/types/courseassignments/dto/IndividualCourseAssignmentDto";
import { daysDifferenceFromNowToDate } from "../../../utils/utils";
import { ManagerCourseAssignmentStore } from "../stores/managerCourseAssignmentStore/ManagerCourseAssignmentStore";

export class IndividualCourseAssignment {
  @observable id: string;

  @observable userId: string;

  @observable companyId: CompanyId;

  @observable stage: AssignmentStageDto;

  @observable isRemovable: boolean;

  @observable completedAt?: Date;

  @observable activeTo: Date;

  @observable hasPassed?: boolean;

  @observable hasDiploma: boolean;

  @observable completedModulesCount: number;

  @observable userFirstName?: string;

  @observable userLastName?: string;

  @observable userEmail: string;

  @computed get remainingDays() {
    if (this.stage === AssignmentStageDto.PastDue || this.stage === AssignmentStageDto.Historical) {
      return;
    }

    return daysDifferenceFromNowToDate(this.activeTo);
  }

  readonly _type = "individualCourseAssignment";

  constructor(private managerCourseAssignmentStore: ManagerCourseAssignmentStore, dto: IndividualCourseAssignmentDto) {
    makeObservable(this);

    this.id = dto.id;
    this.userId = dto.userId;
    this.companyId = new CompanyId(dto.companyId);
    this.stage = dto.stage;
    this.isRemovable = dto.isRemovable;
    this.completedAt = dto.completedAt ? new Date(dto.completedAt) : undefined;
    this.activeTo = new Date(dto.activeTo);
    this.hasPassed = dto.hasPassed;
    this.hasDiploma = dto.hasDiploma;
    this.completedModulesCount = dto.completedModulesCount;
    this.userFirstName = dto.firstName;
    this.userLastName = dto.lastName;
    this.userEmail = dto.email;
  }

  async remove() {
    await this.managerCourseAssignmentStore.removeIndividualCourseAssignment(this.id, this.companyId);
  }
}
