import first from "lodash/first";
import last from "lodash/last";
import { computed, makeObservable, observable } from "mobx";
import { ModuleContentId } from "../../../infrastructure/types/content/model/ModuleContentId";
import { TestContentId } from "../../../infrastructure/types/content/model/TestContentId";
import { ModuleDto } from "../../../infrastructure/types/coursecontents/dto/ModuleDto";
import { ModuleInfoDto } from "../../../infrastructure/types/coursecontents/dto/ModuleInfoDto";
import { ImageDto } from "../../../infrastructure/types/shared/dto/ImageDto";
import { throwExpression } from "../../../utils/utils";
import { Chapter } from "./Chapter";
import { CourseDetailed, CourseInfo } from "./Course";

export type Module = {
  id: ModuleContentId;

  slug: string;

  title: string;

  image: ImageDto;

  duration: number;

  chaptersCount: number;

  testId?: TestContentId;

  _type: "moduleInfo" | "moduleDetailed";
};

export class ModuleInfo {
  @observable id: ModuleContentId;

  @observable slug: string;

  @observable title: string;

  @observable image: ImageDto;

  @observable duration: number;

  @observable chaptersCount: number;

  @observable testId?: TestContentId;

  readonly _type = "moduleInfo";

  constructor(public course: CourseInfo, dto: ModuleInfoDto) {
    makeObservable(this);

    this.id = dto.id;
    this.slug = dto.slug;
    this.title = dto.title;
    this.image = dto.image;
    this.duration = dto.duration;
    this.chaptersCount = dto.chaptersCount;
    this.testId = dto.testId;
  }
}

export class ModuleDetailed {
  @observable id: ModuleContentId;

  @observable slug: string;

  @observable title: string;

  @observable image: ImageDto;

  @observable duration: number;

  @observable chapters: Chapter[] = [];

  @computed get chaptersCount() {
    return this.chapters.length;
  }

  @observable testId?: TestContentId;

  @computed get firstChapter() {
    const firstChapter = first(this.chapters);
    return firstChapter ?? throwExpression(`Module '${this.slug}' must contain at least one chapter`);
  }

  @computed get lastChapter() {
    const lastChapter = last(this.chapters);
    return lastChapter ?? throwExpression(`Module '${this.slug}' must contain at least one chapter`);
  }

  @computed get hasTest() {
    return !!this.testId;
  }

  readonly _type = "moduleDetailed";

  constructor(public course: CourseDetailed, dto: ModuleDto) {
    makeObservable(this);

    this.id = dto.id;
    this.slug = dto.slug;
    this.title = dto.title;
    this.image = dto.image;
    this.duration = dto.duration;
    this.chapters = dto.chapters.map(c => new Chapter(this, c));
    this.testId = dto.testId;
  }
}
