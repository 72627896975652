//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ModuleProgressDto } from '../../progress/dto/ModuleProgressDto';
import { IApiRequest } from '../../IApiRequest';

export class GetModuleProgressByCourseAssignmentRequest extends IApiRequest<ModuleProgressDto[]>
{
	public $type = 'GetModuleProgressByCourseAssignmentRequest';
	constructor(public data: { courseAssignmentId: string }) { super(); }
}
