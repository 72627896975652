//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IApiRequest } from '../../IApiRequest';

export class AddCourseAssignmentsRequest extends IApiRequest<void>
{
	public $type = 'AddCourseAssignmentsRequest';
	constructor(public data: { companyId: string, courseId: string, userIds: string[], groupIds?: string[], activeFrom: string, activeTo: string }) { super(); }
}
