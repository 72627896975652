//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { UserAndGroupItemDto } from '../../users/dto/UserAndGroupItemDto';
import { PagedResultDto } from '../../shared/dto/PagedResultDto';
import { IApiRequest } from '../../IApiRequest';
import { SearchTypeDto } from '../../users/dto/SearchTypeDto';

export class SearchUsersAndGroupsRequest extends IApiRequest<PagedResultDto<UserAndGroupItemDto>>
{
	public $type = 'SearchUsersAndGroupsRequest';
	constructor(public data: { companyId: string, searchType: SearchTypeDto, skip: number, take: number, searchPhrase?: string }) { super(); }
}
