import axios, { AxiosInstance, CancelToken } from "axios";
import { BadRequestObjectResultDto } from "infrastructure/types/api/error/BadRequestObjectResultDto";
import { IApiRequest } from "../types/IApiRequest";
import { HttpCustomError } from "./HttpCustomError";
import { HttpError } from "./HttpError";
import { RequestCancelledError } from "./RequestCancelledError";

type ExtractGeneric<Type> = Type extends IApiRequest<infer X> ? X : never;

type ResponseType = "json" | "blob";

// Neccessary hardcoded 'type' property name
const isBadRequestObjectResultDto = (object: unknown): object is BadRequestObjectResultDto => {
  return Object.prototype.hasOwnProperty.call(object, "type");
};

export class HttpClient {
  constructor(private axiosInstance: AxiosInstance) {}

  async send<T extends { $type: string; data?: unknown }>(
    requestData: T,
    cancelToken: CancelToken | undefined = undefined,
    responseType: ResponseType = "json"
  ): Promise<ExtractGeneric<T>> {
    try {
      const response = await this.axiosInstance.request<ExtractGeneric<T>>({
        url: requestData.$type.replace("Request", ""),
        method: "POST",
        baseURL: "/api",
        data: requestData.data,
        responseType,
        cancelToken,
      });

      return response.data;
    } catch (e: unknown) {
      if (axios.isCancel(e)) {
        throw new RequestCancelledError();
      }
      if (axios.isAxiosError(e)) {
        if (e?.response?.data && isBadRequestObjectResultDto(e.response.data)) {
          throw new HttpCustomError({
            errorCode: e.response.status,
            errorText: e.response.statusText,
            type: e.response.data.type,
          });
        } else if (e.response) {
          throw new HttpError({ errorCode: e.response.status, errorText: e.response.statusText });
        }
      }
      throw e;
    }
  }
}
