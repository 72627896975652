import { HttpClient } from "infrastructure/api/HttpClient";
import { paymentFailedUrl, paymentSuccessUrl } from "infrastructure/paths/AppPaths";
import { CheckoutCourseRequest } from "infrastructure/types/api/landing/CheckoutCourseRequest";
import { CheckoutPackageRequest } from "infrastructure/types/api/landing/CheckoutPackageRequest";
import { PostCheckoutRequest } from "infrastructure/types/api/landing/PostCheckoutRequest";
import { GetContactPageContentRequest } from "infrastructure/types/landing/contact/GetContactPageContentRequest";
import { GetCoursePackagesRequest } from "infrastructure/types/landing/coursepackages/GetCoursePackagesRequest";
import { GetPackageRequest } from "infrastructure/types/landing/coursepackages/GetPackageRequest";
import { GetBuyCourseDetailsRequest } from "infrastructure/types/landing/courseslist/GetBuyCourseDetailsRequest";
import { GetCoursesListRequest } from "infrastructure/types/landing/courseslist/GetCoursesListRequest";
import { GetHomePageContentRequest } from "infrastructure/types/landing/home/GetHomePageContentRequest";

export class LandingStore {
  constructor(private httpClient: HttpClient) {}

  async getHomePageContent() {
    const response = await this.httpClient.send(new GetHomePageContentRequest());
    return response;
  }

  async getContactPageContent() {
    const response = await this.httpClient.send(new GetContactPageContentRequest());
    return response;
  }

  async getCoursesListPageContent() {
    const response = await this.httpClient.send(new GetCoursesListRequest({}));
    return response;
  }

  async getCourse(slug: string) {
    const response = await this.httpClient.send(new GetBuyCourseDetailsRequest({ slug }));
    return response;
  }

  async getCoursePackages() {
    const response = await this.httpClient.send(new GetCoursePackagesRequest({}));
    return response;
  }

  async getCoursePackage(slug: string) {
    const response = await this.httpClient.send(new GetPackageRequest({ slug }));
    return response;
  }

  async checkoutCourse(courseSlug: string, currentLocation: string) {
    const response = await this.httpClient.send(
      new CheckoutCourseRequest({
        courseSlug,
        checkoutUrls: {
          successUrl: paymentSuccessUrl,
          failUrl: paymentFailedUrl,
          cancelUrl: currentLocation,
        },
      })
    );
    return response;
  }

  async checkoutPackage(packageSlug: string, currentLocation: string) {
    const response = await this.httpClient.send(
      new CheckoutPackageRequest({
        packageSlug,
        checkoutUrls: {
          successUrl: paymentSuccessUrl,
          failUrl: paymentFailedUrl,
          cancelUrl: currentLocation,
        },
      })
    );
    return response;
  }

  async postCheckout() {
    const response = await this.httpClient.send(new PostCheckoutRequest());
    return response;
  }
}
