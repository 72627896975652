//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IApiRequest } from '../../IApiRequest';

export class RemoveUsersFromCompanyRequest extends IApiRequest<void>
{
	public $type = 'RemoveUsersFromCompanyRequest';
	constructor(public data: { userIds: string[], companyId: string }) { super(); }
}
