//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { SiteConfigDto } from '../../config/dto/SiteConfigDto';
import { IApiRequest } from '../../IApiRequest';

export class GetSiteConfigRequest extends IApiRequest<SiteConfigDto>
{
	public $type = 'GetSiteConfigRequest';
	constructor(public data: { lang?: string }) { super(); }
}
