//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CompanyUserDto } from '../../company/dto/CompanyUserDto';
import { PagedResultDto } from '../../shared/dto/PagedResultDto';
import { IApiRequest } from '../../IApiRequest';

export class GetCompanyUsersRequest extends IApiRequest<PagedResultDto<CompanyUserDto>>
{
	public $type = 'GetCompanyUsersRequest';
	constructor(public data: { companyId: string, skip: number, take: number, myNetworkOnly: boolean, searchPhrase?: string, activeOnly?: boolean, invitedOnly?: boolean, expiredOnly?: boolean }) { super(); }
}
