//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IApiRequest } from '../../IApiRequest';
import { FilterValueDto } from './FilterValueDto';

export class GetCourseAssignmentsReportRequest extends IApiRequest<Blob>
{
	public $type = 'GetCourseAssignmentsReportRequest';
	constructor(public data: { companyId: string, years: number[], groups: FilterValueDto, users: FilterValueDto, courses: FilterValueDto }) { super(); }
}
