import { observable, makeObservable } from "mobx";
import { ModuleContentId } from "../../../infrastructure/types/content/model/ModuleContentId";
import { ModuleProgressDto } from "../../../infrastructure/types/progress/dto/ModuleProgressDto";

export class ModuleCompletion {
  @observable completedAt: Date;

  @observable moduleContentId: ModuleContentId;

  constructor(dto: ModuleProgressDto) {
    makeObservable(this);
    this.moduleContentId = dto.moduleContentId;
    this.completedAt = new Date(dto.completedAt);
  }
}
