//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum UserRoleDto { 
	Member = "Member", 
	Manager = "Manager", 
	Admin = "Admin", 
	PaidUser = "PaidUser"
}
