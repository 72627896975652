//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GroupLeaderDto } from '../../group/dto/GroupLeaderDto';
import { PagedResultDto } from '../../shared/dto/PagedResultDto';
import { IApiRequest } from '../../IApiRequest';

export class GetGroupLeadersRequest extends IApiRequest<PagedResultDto<GroupLeaderDto>>
{
	public $type = 'GetGroupLeadersRequest';
	constructor(public data: { companyId: string, groupId: string, skip: number, take: number }) { super(); }
}
